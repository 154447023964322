import React, { useState, useEffect, useRef, useCallback } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { PhoneIcon, ChevronDown, ChevronLeft, ChevronRight, Factory, DollarSign, ClipboardList, Truck, Plane, Ship, Train, Package } from 'lucide-react';
import Logo from '../assests/Logo.svg';

const Header = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [isServicesDropdownOpen, setIsServicesDropdownOpen] = useState(false);
  const [isTrackingModalOpen, setIsTrackingModalOpen] = useState(false);
  const [isTrackingModalVisible, setIsTrackingModalVisible] = useState(false);
  
  const sidebarRef = useRef(null);
  const dropdownRef = useRef(null);
  const trackingModalRef = useRef(null);
  const servicesToggleRef = useRef(null);
  
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname, location.search]);

  useEffect(() => {
    const formScript = document.createElement('script');
    formScript.src = 'https://forms.yandex.ru/_static/embed.js';
    formScript.async = true;
    formScript.onload = () => 
    console.log('');
    document.head.appendChild(formScript);

    return () => {
      document.head.removeChild(formScript);
    };
  }, []);

  const openModal = () => {
    setIsModalVisible(true);
    setTimeout(() => {
      setIsModalOpen(true);
    }, 100);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setTimeout(() => {
      setIsModalVisible(false);
    }, 300);
  };

  const toggleModal = () => {
    if (isModalOpen) {
      closeModal();
    } else {
      openModal();
    }
  };

  const openTrackingModal = (isFromSidebar = false) => {
    setIsSidebarOpen(false);
    setTimeout(() => {
      setIsTrackingModalVisible(true);
      setTimeout(() => {
        setIsTrackingModalOpen(true);
      }, 100);
    }, 300);
  };

  const closeTrackingModal = useCallback(() => {
    setIsTrackingModalOpen(false);
    setTimeout(() => {
      setIsTrackingModalVisible(false);
    }, 300);
  }, []);


  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const toggleServicesDropdown = () => {
    setIsServicesDropdownOpen(!isServicesDropdownOpen);
  };

  const handleClickOutside = useCallback((event) => {
    if (trackingModalRef.current && !trackingModalRef.current.contains(event.target)) {
      closeTrackingModal();
    }
    if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
      setIsSidebarOpen(false);
    }
    if (dropdownRef.current && !dropdownRef.current.contains(event.target) && !servicesToggleRef.current.contains(event.target)) {
      setIsServicesDropdownOpen(false);
    }
  }, [closeTrackingModal]);

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [handleClickOutside]);

  const handleLinkClick = () => {
    setIsSidebarOpen(false);
    setIsServicesDropdownOpen(false);
  };

  const navItems = [
    { href: "/?page=home", label: "Главная" },
    { href: "/?page=about-us", label: "О Нас" },
    {
      label: "Услуги",
      dropdown: true,
      items: [
        { href: "/?page=manufacturer-verification", icon: <Factory className="mr-2 h-6 w-6 text-dark" />, label: "Поиск и проверка производителей" },
        { href: "/?page=money-transfer", icon: <DollarSign className="mr-2 h-6 w-6 text-dark" />, label: "Перевод и обмен денежных средств" },
        { href: "/?page=customs-clearance", icon: <ClipboardList className="mr-2 h-6 w-6 text-dark" />, label: "Таможенное оформление" },
        { href: "/?page=road-transport", icon: <Truck className="mr-2 h-6 w-6 text-dark" />, label: "Автоперевозки" },
        { href: "/?page=air-transport", icon: <Plane className="mr-2 h-6 w-6 text-dark" />, label: "Авиаперевозки" },
        { href: "/?page=maritime-transport", icon: <Ship className="mr-2 h-6 w-6 text-dark" />, label: "Морские перевозки" },
        { href: "/?page=railway-transport", icon: <Train className="mr-2 h-6 w-6 text-dark" />, label: "Железнодорожные перевозки из Китая" },
        { href: "#", icon: <Package className="mr-2 h-6 w-6 text-dark" />, label: "Трек Ордер", action: () => openTrackingModal(false) },
        { href: "/?page=services", icon: <ChevronDown className="mr-2 h-6 w-6 text-dark" />, label: "Все услуги" }
      ]
    },
    { href: "/?page=contact", label: "Контакты" },
    { label: "Рассчитать", action: toggleModal },
  ];

  const sidebarItems = [
    { href: "/?page=home", label: "Главная" },
    { href: "/?page=about-us", label: "О Нас" },
    {
      label: "Услуги",
      dropdown: true,
      items: [
        { href: "/?page=manufacturer-verification", icon: <Factory className="mr-2 h-6 w-6 text-dark" />, label: "Поиск и проверка производителей" },
        { href: "/?page=money-transfer", icon: <DollarSign className="mr-2 h-6 w-6 text-dark" />, label: "Перевод и обмен денежных средств" },
        { href: "/?page=customs-clearance", icon: <ClipboardList className="mr-2 h-6 w-6 text-dark" />, label: "Таможенное оформление" },
        { href: "/?page=road-transport", icon: <Truck className="mr-2 h-6 w-6 text-dark" />, label: "Автоперевозки" },
        { href: "/?page=air-transport", icon: <Plane className="mr-2 h-6 w-6 text-dark" />, label: "Авиаперевозки" },
        { href: "/?page=maritime-transport", icon: <Ship className="mr-2 h-6 w-6 text-dark" />, label: "Морские перевозки" },
        { href: "/?page=railway-transport", icon: <Train className="mr-2 h-6 w-6 text-dark" />, label: "Железнодорожные перевозки из Китая" },
        { href: "#", label: "Трек Ордер", icon: <Package className="mr-2 h-6 w-6 text-dark" />, action: () => openTrackingModal(true) },
        { href: "/?page=services", icon: <ChevronDown className="mr-2 h-6 w-6 text-dark" />, label: "Все услуги" }
      ]
    },
    { href: "/?page=contact", label: "Контакты" },
    { href: "/?page=calculate", label: "Рассчитать" },
  ];

  window.openModal = openModal;

  return (
    <header className="fixed w-full z-50 bg-white shadow-2xl">
      <div className="mx-auto py-2 md:py-4 max-w-screen-xl px-4 sm:px-6 lg:px-8">
        <div className="flex h-12 sm:h-14 md:h-16 items-center justify-between">
          <div className="flex-1 md:flex md:items-center md:gap-12">
            <Link className='flex  px-3 rounded-lg mt-1 items-center' to="/?page=home" onClick={handleLinkClick}>
              <div className='flex items-center'>
                <img src={Logo} className='w-12 h-12 lg:w-20 lg:h-20' alt='logo'/>
                <h1 className="lg:text-[66px] text-4xl mx-2 font-extrabold flex space-x-2">
                  <span className='text-dark' >0</span>
                  <span className='text-dark' >0</span>
                  <span className='text-dark' >2</span>
                </h1>
              </div>
            </Link>
          </div>

          <div className="md:flex md:items-center md:gap-12">
            <nav aria-label="Global" className="hidden md:block">
              <ul className="flex items-center gap-6 text-[17px] font-semibold text-dark">
                {navItems.map((item, index) => (
                  <li key={index} className="nav-item relative">
                    {item.dropdown ? (
                      <>
                        <div ref={servicesToggleRef} className="flex items-center cursor-pointer" onClick={toggleServicesDropdown}>
                          <span>{item.label}</span>
                          <ChevronDown className="ml-1 mt-1 w-5 h-5 text-dark" />
                        </div>
                        {isServicesDropdownOpen && (
                          <ul ref={dropdownRef} className="absolute left-0 mt-2 bg-white shadow-lg rounded-lg w-80">
                            {item.items.map((subItem, subIndex) => (
                              <li key={subIndex} className="dropdown-item py-2 px-4 hover:bg-neutral-100 flex items-center rounded">
                                {subItem.icon}
                                {subItem.action ? (
                                  <button onClick={subItem.action} className="text-gray-500 text-sm">{subItem.label}</button>
                                ) : (
                                  <Link to={subItem.href} className="text-gray-500 text-sm" onClick={handleLinkClick}>{subItem.label}</Link>
                                )}
                              </li>
                            ))}
                          </ul>
                        )}
                      </>
                    ) : item.href ? (
                      <Link to={item.href} onClick={handleLinkClick}>{item.label}</Link>
                    ) : (
                      <button onClick={item.action}>{item.label}</button>
                    )}
                  </li>
                ))}
              </ul>
            </nav>

            <div className="flex items-center gap-4">
              <div className="hidden lg:flex items-center gap-2 px-4 py-3 bg-dark rounded">
                <PhoneIcon className="text-white  h-7 w-7" />
                <span className="text-white font-bold text-xl">+79165150002</span>
              </div>

              <div className="block md:hidden">
                <button onClick={toggleSidebar} className="rounded bg-gray-100 p-2 text-gray-600 transition hover:text-gray-600/75">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-5 w-5"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth="2"
                  >
                    <path strokeLinecap="round" strokeLinejoin="round" d="M4 6h16M4 12h16M4 18h16" />
                  </svg>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      {isModalVisible && (
        <div className={`fixed inset-0 z-50 flex items-center justify-end bg-black bg-opacity-50 transition-opacity duration-300 ${isModalOpen ? 'opacity-100' : 'opacity-0'}`}>
          <div className={`bg-white p-4 md:w-[40%] w-full h-full md:h-auto rounded-lg shadow-lg modal-content relative transition-transform duration-300 transform ${isModalOpen ? 'translate-x-0' : 'translate-x-full'}`}>
            <button
              className="absolute top-1/2 -left-5 transform -translate-y-1/2 bg-white p-1 hover:bg-dark rounded-full shadow-lg"
              onClick={toggleModal}
            >
              {isModalOpen ? <ChevronRight className="h-10 w-10 text-dark hover:text-white" /> : <ChevronLeft className="h-6 w-6 text-gray-500 hover:text-gray-700" />}
            </button>
            <iframe
              src="https://forms.yandex.ru/cloud/66a4fa7202848f29816e7368/?iframe=1"
              frameBorder="0"
              name="ya-form-66a4fa7202848f29816e7368"
              title="Yandex Form"
              width="100%"
              height="100%"
            ></iframe>
          </div>
        </div>
      )}

      {isTrackingModalVisible && (
        <div className={`fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50 transition-opacity duration-300 ${isTrackingModalOpen ? 'opacity-100' : 'opacity-0'}`}>
          <div ref={trackingModalRef} className={`bg-white p-4 ${isSidebarOpen ? 'w-[90%]' : 'md:w-[40%]'} w-full h-auto rounded-lg shadow-lg modal-content relative transition-transform duration-300 transform ${isTrackingModalOpen ? 'translate-x-0' : 'translate-x-full'}`}>
            <button
              className="absolute top-2 right-2 bg-dark p-2 hover:bg-gray-700 rounded-full shadow-lg"
              onClick={closeTrackingModal}
            >
              <ChevronRight className="h-6 w-6 text-white" />
            </button>
            <h2 className="text-xl font-semibold text-center mb-4">Отслеживать вашего Груза</h2>
            <iframe
              src="https://forms.yandex.ru/cloud/66b4c9adf47e730e1973cf5b/?iframe=1"
              frameBorder="0"
              name="ya-form-66b4c9adf47e730e1973cf5b"
              title="Yandex Form"
              width="100%"
              height="400px"
            ></iframe>
          </div>
        </div>
      )}

      {isSidebarOpen && (
        <div className="fixed inset-0 z-50 flex items-center justify-start bg-black bg-opacity-50 transition-opacity duration-300">
          <div ref={sidebarRef} className="bg-white p-4 w-80 h-full rounded-r-lg shadow-lg modal-content relative transform transition-transform duration-300 ease-in-out translate-x-0 overflow-y-auto">
            <ul className="space-y-4 text-dark font-semibold">
              {sidebarItems.map((item, index) => (
                <li key={index} className="nav-item">
                  {item.dropdown ? (
                    <div>
                      <div className="flex items-center py-2 px-4 cursor-pointer transition duration-300 ease-in-out hover:bg-gray-100" onClick={item.action || toggleServicesDropdown}>
                        <span>{item.label}</span>
                        <ChevronDown className="ml-1 mt-1 w-5 h-5" />
                      </div>
                      {isServicesDropdownOpen && (
                        <ul ref={dropdownRef} className="mt-2 space-y-2">
                          {item.items && item.items.map((subItem, subIndex) => (
                            <li key={subIndex} className="py-2 px-4 hover:bg-gray-100 flex items-center rounded transition duration-300 ease-in-out dropdown-item">
                              {subItem.icon}
                              {subItem.action ? (
                                <button onClick={subItem.action} className="text-dark text-sm" >{subItem.label}</button>
                              ) : (
                                <Link to={subItem.href} className="text-dark text-sm" onClick={handleLinkClick}>{subItem.label}</Link>
                              )}
                            </li>
                          ))}
                        </ul>
                      )}
                    </div>
                  ) : (
                    <Link to={item.href} className="block py-2 px-4 hover:bg-gray-100 rounded transition duration-300 ease-in-out" onClick={handleLinkClick}>{item.label}</Link>
                  )}
                </li>
              ))}
            </ul>
            <div className="flex flex-col justify-center mt-auto pt-4 items-center">
              <img src={Logo} className='w-24 h-24 mt-20' alt='logo'/>
              <p className="text-center font-semibold mt-2">k002 Карго | 天佑国际物流</p>
            </div>
          </div>
        </div>
      )}
    </header>
  );
}

export default Header;
