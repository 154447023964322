import React from 'react';
import { Helmet } from 'react-helmet';
import BgHero from '../components/BgHero';
import OurServices from '../components/Services';

const Services = () => {
  const metadata = {
    title: 'Наши Услуги - Транспортные и Логистические Решения |  К002 карго',
    description: 'Мы предоставляем широкий спектр транспортных и логистических услуг.',
    keywords: 'Услуги, Services, 服务, Transport, Транспорт, 运输, Logistics, Логистика, 物流',
    author: 'Транспортная Компания',
    language: 'ru',
    openGraph: {
      title: 'Наши Услуги - Транспортные и Логистические Решения |  К002 карго',
      description: 'Мы предоставляем широкий спектр транспортных и логистических услуг.',
      image: 'https://www.k-002.ru/static/media/Logo.33e265985101896cf6b1cb98d2399010.svg',
      url: 'https://K-002.ru/services',
      type: 'website',
    },
  };

  return (
    <div>
      <Helmet>
        <title>{metadata.title}</title>
        <meta name="description" content={metadata.description} />
        <meta name="keywords" content={metadata.keywords} />
        <meta name="author" content={metadata.author} />
        <meta name="language" content={metadata.language} />
        <meta name="robots" content="index, follow" />
        <link rel="canonical" href={metadata.openGraph.url} />
        <meta property="og:title" content={metadata.openGraph.title} />
        <meta property="og:description" content={metadata.openGraph.description} />
        <meta property="og:image" content={metadata.openGraph.image} />
        <meta property="og:url" content={metadata.openGraph.url} />
        <meta property="og:type" content={metadata.openGraph.type} />
      </Helmet>
      <BgHero headline="Услуги" />
      <OurServices />
    </div>
  );
}

export default Services;
