import React from 'react';
import ServiceItem from './ServiceItem';

const ServiceMenu = () => {
  const handleButtonClick = () => {
    if (window.innerWidth < 1024) {
      window.location.href = "https://www.k-002.ru/?page=calculate";
    } else {
      window.openModal();
    }
  };

  return (
    <div className="flex flex-col gap-5 -mt-5 md:mt-0">
      <section className="md:order-2 order-1 rounded-xl border-2 border-dark shadow-2xl">
        <div className="p-8 text-center">
          <h2 className="text-2xl text-gray-500 font-bold">Если вы хотите оформить заказ нажимайте кнопку</h2>
          <button
            className="mt-8 inline-block w-full rounded-full bg-dark py-4 text-sm font-bold text-white shadow-xl cursor-pointer"
            onClick={handleButtonClick}
          >
            Начинать
          </button>
        </div>
      </section>
      
      {/* Service items div */}
      <div className="md:order-1 order-2 bg-dark py-1 text-white rounded-md font-light">
        <ServiceItem 
          item="Поиск и проверка производителей" 
          path="/?page=manufacturer-verification" 
        />
        <ServiceItem 
          item="Перевод и обмен денежных средств" 
          path="/?page=money-transfer" 
        />
        <ServiceItem 
          item="Таможенное оформление" 
          path="/?page=customs-clearance" 
        />
        <ServiceItem 
          item="Автоперевозки" 
          path="/?page=road-transport" 
        />
        <ServiceItem 
          item="Авиаперевозки" 
          path="/?page=air-transport" 
        />
        <ServiceItem 
          item="Морские перевозки" 
          path="/?page=maritime-transport" 
        />
        <ServiceItem 
          item="Железнодорожные перевозки из Китая" 
          path="/?page=railway-transport" 
        />
      </div>
    </div>
  );
};

export default ServiceMenu;
