const DetailsSection = () => {
    return (
      <div className="mt-10  p-4 bg-white rounded-lg shadow-md">
        <h1 className="md:text-3xl text-2xl font-bold text-dark mb-6">Детали</h1>
        <h2 className="md:text-2xl text-lg font-bold text-dark mb-4">Авиаперевозки</h2>
        <p className="text-gray-500 text-sm md:text-base ">
          Маршрут: Склад в Китае - Аэропорт – Аэропорт в Москве, Санкт-Петербурге,
          Новосибирске (или другие города).
        </p>
        <p className="text-gray-500 text-sm md:text-base mt-2">
          Сроки: Быстрая авиадоставка 5-7 дней Медленная авиадоставка 8-12 дней
          (со дня, следующего за днём получения)
        </p>
        <h3 className="md:text-xl  font-semibold text-dark mt-4">
          Города в России, в которые мы осуществляем доставку товара:
        </h3>
        <p className="text-gray-500 text-sm md:text-base mt-2">
          Москва, Санкт-Петербург, Екатеринбург, Новосибирск, Сочи, Тюмень, Уфа,
          Казань, Чита, Самара, Иркутск, Пермь, Владивосток, Улан-Удэ, Омск,
          Якутск, Воронеж, Ростов, Уссурийск, Хабаровск, Краснодар, Красноярск и
          другие крупные города.
        </p>
        <h3 className="md:text-xl font-semibold text-dark mt-4">
          Транспортная упаковка:
        </h3>
        <p className="text-gray-500 text-sm md:text-base mt-2">
          В связи с длительным транзитным временем при международных перевозках
          для товаров необходима водонепроницаемая упаковка и деревянные ящики,
          чтобы предотвратить повреждения в дороге (повреждения возникают из-за
          давления и ударов деревянных ящиков друг о друга) и намокание товаров.
          Упаковка: деревянные ящики (80 долларов США за кубический метр),
          деревянные рамы (50 долларов США за кубический метр), с дополнительной
          платой за вес. Водонепроницаемая упаковка (скотч и мешки (US$5 за
          мешок)).
        </p>
        <h3 className="md:text-xl font-semibold text-dark mt-4">
          Товары, имеющие преференцию при таможенном оформлении:
        </h3>
        <p className="text-gray-500 text-sm md:text-base mt-2">
          Компьютер, карманный компьютер MID, планшетный компьютер, мобильный
          телефон, детали мобильного телефона, лампы и фонари, автозапчасти,
          строительные материалы, детали оборудования, одежда, обувь и головные
          уборы, мебель, сумки, кожа, постельные принадлежности, игрушки, изделия
          народных промыслов, сантехника, продукты питания, медицина, техника и
          т.д.
        </p>
        <h3 className="md:text-xl font-semibold text-dark mt-4">Страхование:</h3>
        <p className="text-gray-500 text-sm md:text-base mt-2">
          Сумма страхования: 2% от стоимости товара. Если товар будет утерян, мы
          выплатим вам полную стоимость товара.
        </p>
        <h3 className="md:text-xl font-semibold text-dark mt-4">
          Компенсация за позднее прибытие:
        </h3>
        <p className="text-gray-500 text-sm md:text-base mt-2">
          Авиа: 0,1$/кг/день, если груз не доставлен в Москву на 13 день после
          отправки. Автомобильным транспортом: 5$/куб.м/день, если груз не
          прибывает в Москву на 25 день после отправки.
        </p>
      </div>
    );
  };
  
  export default DetailsSection;
  