import React from 'react';
import { Link } from 'react-router-dom';

const Card = ({ icon, title, paragraph, contact, link }) => {
    const renderParagraph = () => {
        if (contact && title === "Социальные сети") {
            return (
                <div className="mt-2 text-gray-500 text-sm md:text-base text-center">
                    <a href="https://t.me/k002cargo1" className="text-dark" target="_blank" rel="noopener noreferrer">Telegram</a> | 
                    <a href="https://vk.com/k002k" className="text-dark" target="_blank" rel="noopener noreferrer"> VK</a>
                </div>
            );
        }
        return <p className="mt-2 text-sm md:text-base text-gray-500 text-center">{paragraph}</p>;
    };

    return (
        <div className="card-container relative w-full h-[240px] md:h-[360px] overflow-hidden">
            <div className={`card relative w-full h-full ${!contact ? 'hover:flip-card' : ''}`}>
                <div className="front absolute inset-0 flex flex-col items-center justify-center md:p-10 p-4 bg-white border border-gray-200 rounded-xl shadow-lg">
                    <div className="mb-2 md:mb-4 p-4 md:p-7 rounded-xl bg-dark/95">
                        {icon}
                    </div>
                    <h3 className="md:text-xl font-semibold text-dark text-center">{title}</h3>
                    {renderParagraph()}
                </div>
                {!contact && (
                    <div className="back absolute inset-0 flex flex-col items-center justify-center p-10 bg-white border border-gray-200 rounded-xl shadow-lg backface-hidden transform rotate-y-180">
                        <h3 className="md:text-xl font-semibold text-dark text-center">{title}</h3>
                        <p className="mt-2 text-sm md:text-base text-gray-500 text-center">{paragraph}</p>
                        <Link to={link}>
                            <button className="mt-4 p-3 md:p-4 md:text-base text-sm  bg-dark text-white rounded">
                                Подробнее
                            </button>
                        </Link>
                    </div>
                )}
            </div>
        </div>
    );
}

export default Card;
