import { useState, useEffect } from "react";
import { Globe, Clock } from "lucide-react";
import { Link } from 'react-router-dom';
import world from "../assests/world.png";
import transport from "../assests/transportation.png";
import { useIntersectionObserver } from "./useIntersectionObserver";

const FastLogistics = ({ Homepage }) => {
  const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });
  const [isHovered, setIsHovered] = useState(false);
  const [setRef, isVisible] = useIntersectionObserver({
    threshold: 0.3,
  });

  useEffect(() => {
    const handleMouseMove = (e) => {
      setMousePosition({ x: e.clientX, y: e.clientY });
    };

    if (isHovered) {
      window.addEventListener("mousemove", handleMouseMove);
    } else {
      window.removeEventListener("mousemove", handleMouseMove);
    }

    return () => {
      window.removeEventListener("mousemove", handleMouseMove);
    };
  }, [isHovered]);

  const translateX = (mousePosition.x / window.innerWidth - 0.5) * 30;
  const translateY = (mousePosition.y / window.innerHeight - 0.5) * 30;

  return (
    <div ref={setRef} className="flex flex-col md:gap-10 md:flex-row justify-around items-center lg:mx-20 mx-10 my-5 md:my-20">
      <div
        className={`relative w-[235px] h-[235px] sm:w-[300px] sm:h-[300px] md:w-[360px] md:h-[360px] lg:w-[500px] lg:h-[500px] lg:mb-10 ${isVisible ? 'slide-in-left' : 'element-invisible'}`}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        <div className="absolute inset-0 rounded-full overflow-hidden">
          <img
            src={world}
            alt="world logistics"
            className="animate-spin-slow"
          />
        </div>
        <div
          className="absolute inset-0 flex justify-center items-center"
          style={{
            transform: `translate(${translateX}px, ${translateY}px)`,
            transition: "transform 0.2s ease-out",
          }}
        >
          <img
            src={transport}
            alt="transport overlay"
            className=""
          />
        </div>
      </div>
      <div className={`md:w-[40%] lg:w-2/5 ${isVisible ? 'slide-in-right delay-200' : 'element-invisible'}`}>
        <h2 className="text-2xl md:text-3xl lg:text-4xl text-dark font-bold mb-6">Быстрая Логистика</h2>
        <p className="md:text-lg text-gray-700 mb-6">
          Наша компания, специализирующаяся на разработке практических решений для сложных задач, предоставляет услуги по транспортировке тяжелых грузов. Наш бренд предлагает корпоративные услуги по конкурентоспособным ценам и готов воплотить в жизнь ваши амбициозные проекты.
        </p>
        <div className="flex items-start mb-6">
          <div className="text-xl text-dark mr-4">
            <Globe className="md:w-10 md:h-10 mt-2 md:mt-0" />
          </div>
          <div>
            <h3 className="text-lg font-bold text-dark">Межконтинентальные перевозки</h3>
            <p className="text-[14px] mt-2 md:mt-0 text-gray-700">
              Оптимизируйте свои грузоперевозки с нашими решениями: авиа-, автотранспорт, море и ж/д. Быстро и надежно!
            </p>
          </div>
        </div>
        <div className="flex items-start">
          <div className="text-xl text-dark mr-4">
            <Clock className="md:w-10 md:h-10 mt-2 md:mt-0" />
          </div>
          <div>
            <h3 className="text-lg font-bold text-dark">Быстрая Загрузка</h3>
            <p className="text-[14px] mt-2 md:mt-0 text-gray-700">
              Перевозим ваши тяжелые грузы быстро и вовремя. Надежность и скорость — наши приоритеты!
            </p>
          </div>
        </div>
        {Homepage && (
          <div className="mt-6">
            <Link to="/?page=about-us" className="inline-block bg-dark/95 text-white p-3 md:p-5 text-sm md:text-lg font-bold">
              Узнайте о нас больше
            </Link>
          </div>
        )}
      </div>
    </div>
  );
};

export default FastLogistics;
