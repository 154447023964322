import React from 'react';
import { Helmet } from 'react-helmet';
import BgHero from '../components/BgHero';
import ServiceMain from '../components/ServiceMain';
import ServiceMenu from '../components/ServiceMenu';
import Railway from '../assests/railway.jpg'; 
import medal from '../assests/medal.png';
import badge from '../assests/badge.png';

const RailwayTransport = () => {
  const metadata = {
    title: 'Железнодорожный транспорт - Безопасные и Надежные |  К002 карго',
    description: 'Мы предоставляем безопасные и надежные железнодорожные перевозки.',
    keywords: 'Железнодорожный транспорт, Railway Transport, 铁路运输, Transport, Транспорт, 运输, Logistics, Логистика, 物流',
    author: 'Транспортная Компания',
    language: 'ru',
    openGraph: {
      title: 'Железнодорожный транспорт - Безопасные и Надежные |  К002 карго',
      description: 'Мы предоставляем безопасные и надежные железнодорожные перевозки.',
      image: 'https://www.k-002.ru/static/media/Logo.33e265985101896cf6b1cb98d2399010.svg', 
      url: 'https://K-002.ru/railway-transport',
      type: 'website',
    },
  };

  return (
    <div className="bg-neutral-50">
      <Helmet>
        <title>{metadata.title}</title>
        <meta name="description" content={metadata.description} />
        <meta name="keywords" content={metadata.keywords} />
        <meta name="author" content={metadata.author} />
        <meta name="language" content={metadata.language} />
        <meta name="robots" content="index, follow" />
        <link rel="canonical" href={metadata.openGraph.url} />
        <meta property="og:title" content={metadata.openGraph.title} />
        <meta property="og:description" content={metadata.openGraph.description} />
        <meta property="og:image" content={metadata.openGraph.image} />
        <meta property="og:url" content={metadata.openGraph.url} />
        <meta property="og:type" content={metadata.openGraph.type} />
      </Helmet>
      <BgHero headline="Железнодорожный транспорт"/>
      <div className="mt-8 lg:mx-40 mx-5 sm:mx-10 md:mx-20 flex flex-col lg:flex-row md:pb-32 pb-10 gap-10"> 
        <div className="lg:w-[30%] order-last lg:order-first">
          <div className="lg:sticky top-32">
            <ServiceMenu />
          </div>
        </div>
        <div className="lg:w-[70%]">
          <ServiceMain
            title="Железнодорожный транспорт"
            titlecard1="Лояльность"
            titlecard2="Надежность"
            paragraph="Среди типов железнодорожных вагонов крытые вагоны являются наиболее предпочтительными. Он используется для перевозки бытовых товаров, скота и продуктов, таких как навоз, продовольственные материалы и мешковой цемент. Другой тип вагона — полувагон."
            paragraphcard1="Наша долгосрочная работа продолжается до завершения задачи. Мы устанавливаем прочные и долгосрочные отношения со всеми компаниями, с которыми работаем."
            paragraphcard2="Мы предоставляем точные, надежные и этичные услуги с нашей экспертной командой. Мы применяем самые быстрые и надежные методы для вашего бренда."
            image={Railway}
            cardlogo1={medal} 
            cardlogo2={badge} 
          />
          <div className="mt-10 p-4 bg-white rounded-lg shadow-md">
            <h1 className="md:text-3xl text-xl font-bold text-dark mb-3">Железнодорожный транспорт</h1>
            <p className="text-gray-500 text-sm md:text-base">
              Среди типов железнодорожных вагонов крытые вагоны являются наиболее предпочтительными. Они используются для перевозки бытовых товаров, скота и продуктов, таких как навоз, продовольственные материалы и мешковой цемент. Другой тип вагона — полувагон, который также часто используется для этих целей.
            </p>
            <h3 className="md:text-xl font-semibold text-dark mt-4">Особенности железнодорожных перевозок</h3>
            <p className="text-gray-500 text-sm md:text-base mt-2">
              Железнодорожные перевозки обладают рядом преимуществ, таких как высокая вместимость, надежность и возможность перевозки различных типов грузов на большие расстояния. Железнодорожный транспорт часто используется в сочетании с другими видами транспорта, обеспечивая комплексные логистические решения.
            </p>
            <h3 className="md:text-xl font-semibold text-dark mt-4">Наши услуги в области железнодорожных перевозок</h3>
            <p className="text-gray-500 text-sm md:text-base mt-2">
              Мы предлагаем полный спектр услуг в области железнодорожных перевозок, включая:
            </p>
            <ul className="list-disc ml-5 text-gray-500 text-sm md:text-base mt-2">
              <li>Разработка оптимальных маршрутов перевозки с учетом специфики груза и требований клиента.</li>
              <li>Организация перевозки груза от склада отправителя до железнодорожной станции отправления.</li>
              <li>Таможенное оформление и сопровождение груза на всех этапах перевозки.</li>
              <li>Организация доставки груза "от двери до двери" с использованием мультимодальных схем.</li>
              <li>Отслеживание движения груза и предоставление отчетов о его местоположении в режиме реального времени.</li>
            </ul>
            <p className="text-gray-500 text-sm md:text-base mt-2">
              Мы обеспечиваем высокое качество обслуживания и индивидуальный подход к каждому клиенту. Обратившись к нам, вы можете быть уверены в надежности и своевременности доставки вашего груза.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default RailwayTransport;
