import { useEffect, useState } from "react";

export const useIntersectionObserver = (options) => {
  const [isIntersecting, setIsIntersecting] = useState(false);
  const [element, setElement] = useState(null);

  useEffect(() => {
    if (!element) return;

    const observer = new IntersectionObserver(([entry]) => {
      if (entry.isIntersecting && !isIntersecting) {
        setIsIntersecting(true);
        observer.disconnect(); 
      }
    }, options);

    observer.observe(element);

    return () => observer.disconnect();
  }, [element, options, isIntersecting]);

  return [setElement, isIntersecting];
};
