import React from 'react';
import { Helmet } from 'react-helmet';
import BgHero from '../components/BgHero';
import Card from '../components/Card';
import { Phone, Mail, MapPin, Globe } from 'lucide-react';
import { useIntersectionObserver } from '../components/useIntersectionObserver';

const Contact = () => {
  const metadata = {
    title: 'Контакты - Свяжитесь с Нами | К002 карго',
    description: 'Свяжитесь с нами для любых вопросов или предложений.',
    keywords: 'Контакты, Contact, 联系, Phone, Электронная почта, Email, 办公室, Office, 社交媒体, Social Media',
    author: 'Транспортная Компания',
    language: 'ru',
    openGraph: {
      title: 'Контакты - Свяжитесь с Нами | К002 карго',
      description: 'Свяжитесь с нами для любых вопросов или предложений.',
      image: 'https://www.k-002.ru/static/media/Logo.33e265985101896cf6b1cb98d2399010.svg',
      url: 'https://K-002.ru/contact',
      type: 'website',
    },
  };

  const slides = [
    {
      icon: <Phone size={48} color="white" />,
      title: "Телефон",
      paragraph: (
        <a href="tel:+79165150002" className="text-dark">
          +7 916 515-00-02
        </a>
      ),
      contact: true,
    },
    {
      icon: <Mail size={48} color="white" />,
      title: "Электронная почта",
      paragraph: (
        <a href="mailto:k002kk@bk.ru" className="text-dark">
          k002kk@bk.ru
        </a>
      ),
      contact: true,
    },
    {
      icon: <MapPin size={48} color="white" />,
      title: "Наш офис в Москве",
      paragraph: (
        <a href="https://yandex.ru/maps/-/CDcQ6ZoO" target="_blank" rel="noopener noreferrer" className="text-dark">
          Улица Парковая 41, Котельники, Московская область, Котельники.
        </a>
      ),
      contact: true,
    },
    {
      icon: <Globe size={48} color="white" />,
      title: "Социальные сети",
      paragraph: (
        <div>
          <a href="https://t.me/k002cargo1" className="text-dark">Telegram</a> | 
          <a href="https://vk.com/uslugi-226488226?" className="text-dark"> VKontakte</a>
        </div>
      ),
      contact: true,
    }
  ];

  const [setRef1, isVisible1] = useIntersectionObserver({ threshold: 0.3 });
  const [setRef2, isVisible2] = useIntersectionObserver({ threshold: 0.3 });
  const [setRef3, isVisible3] = useIntersectionObserver({ threshold: 0.3 });
  const [setRef4, isVisible4] = useIntersectionObserver({ threshold: 0.3 });
  const [setFormRef, isFormVisible] = useIntersectionObserver({ threshold: 0.3 });

  return (
    <div className="bg-neutral-50">
      <Helmet>
        <title>{metadata.title}</title>
        <meta name="description" content={metadata.description} />
        <meta name="keywords" content={metadata.keywords} />
        <meta name="author" content={metadata.author} />
        <meta name="language" content={metadata.language} />
        <meta name="robots" content="index, follow" />
        <link rel="canonical" href={metadata.openGraph.url} />
        <meta property="og:title" content={metadata.openGraph.title} />
        <meta property="og:description" content={metadata.openGraph.description} />
        <meta property="og:image" content={metadata.openGraph.image} />
        <meta property="og:url" content={metadata.openGraph.url} />
        <meta property="og:type" content={metadata.openGraph.type} />
      </Helmet>
      <BgHero headline="Контакты" />
      <div className="p-4 py-10 relative sm:mx-10 lg:mx-20 flex flex-col lg:flex-row gap-6 md:gap-10">
        <div className="w-[80%] m-auto lg:w-[70%] grid grid-cols-1 sm:grid-cols-2 gap-4">
          <div ref={setRef1} className={`card-invisible ${isVisible1 ? 'card-visible-1' : ''}`}>
            <Card
              icon={slides[0].icon}
              title={slides[0].title}
              paragraph={slides[0].paragraph}
              contact={slides[0].contact}
            />
          </div>
          <div ref={setRef2} className={`card-invisible ${isVisible2 ? 'card-visible-2' : ''}`}>
            <Card
              icon={slides[1].icon}
              title={slides[1].title}
              paragraph={slides[1].paragraph}
              contact={slides[1].contact}
            />
          </div>
          <div ref={setRef3} className={`card-invisible ${isVisible3 ? 'card-visible-3' : ''}`}>
            <Card
              icon={slides[2].icon}
              title={slides[2].title}
              paragraph={slides[2].paragraph}
              contact={slides[2].contact}
            />
          </div>
          <div ref={setRef4} className={`card-invisible ${isVisible4 ? 'card-visible-4' : ''}`}>
            <Card
              icon={slides[3].icon}
              title={slides[3].title}
              paragraph={slides[3].paragraph}
              contact={slides[3].contact}
            />
          </div>
        </div>
        <div ref={setFormRef} className={`form-invisible ${isFormVisible ? 'form-visible' : ''} w-[90%] m-auto lg:w-[30%] bg-white h-fit p-4 border-2 border-dark rounded-lg shadow-md`}>
          <div className="mb-4 text-center">
            <p className="font-bold text-sm md:text-base text-dark">Заполните форму, и мы свяжемся с вами незамедлительно.</p>
          </div>
          <iframe
            title="Contact Form"
            src="https://forms.yandex.ru/cloud/66ab386cd04688134d51c9dc/?iframe=1"
            name="ya-form-66ab386cd04688134d51c9dc"
            width="100%"
            height="370px"
            className="rounded-lg"
          ></iframe>
        </div>
      </div>
      <div className="w-full md:mt-10 ">
        <p className="text-center mb-4 text-sm md:text-base ">Парковая улица 41, Котельники, Московская обл.,Котельники</p>
        <iframe title="Office Location" src="https://yandex.ru/map-widget/v1/?z=12&ol=biz&oid=19968892657" width="100%" height="400" frameBorder="0"></iframe>
      </div>
    </div>
  );
};

export default Contact;
