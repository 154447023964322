import React from 'react';
import { Helmet } from 'react-helmet';
import BgHero from '../components/BgHero';
import ServiceMain from '../components/ServiceMain';
import ServiceMenu from '../components/ServiceMenu';
import Road from '../assests/Road.jpg'; 
import medal from '../assests/medal.png';
import badge from '../assests/badge.png';

const RoadHaulage = () => {
  const metadata = {
    title: 'Автомобильные перевозки - Безопасные и Надежные |  К002 карго',
    description: 'Мы предоставляем безопасные и надежные автомобильные перевозки.',
    keywords: 'Автомобильные перевозки, Road Haulage, 公路运输, Transport, Транспорт, 运输, Logistics, Логистика, 物流',
    author: 'Транспортная Компания',
    language: 'ru',
    openGraph: {
      title: 'Автомобильные перевозки - Безопасные и Надежные |  К002 карго',
      description: 'Мы предоставляем безопасные и надежные автомобильные перевозки.',
      image: 'https://www.k-002.ru/static/media/Logo.33e265985101896cf6b1cb98d2399010.svg', 
      url: 'https://K-002.ru/road-haulage',
      type: 'website',
    },
  };

  return (
    <div className="bg-neutral-50">
      <Helmet>
        <title>{metadata.title}</title>
        <meta name="description" content={metadata.description} />
        <meta name="keywords" content={metadata.keywords} />
        <meta name="author" content={metadata.author} />
        <meta name="language" content={metadata.language} />
        <meta name="robots" content="index, follow" />
        <link rel="canonical" href={metadata.openGraph.url} />
        <meta property="og:title" content={metadata.openGraph.title} />
        <meta property="og:description" content={metadata.openGraph.description} />
        <meta property="og:image" content={metadata.openGraph.image} />
        <meta property="og:url" content={metadata.openGraph.url} />
        <meta property="og:type" content={metadata.openGraph.type} />
      </Helmet>
      <BgHero headline="Автомобильные перевозки"/>
      <div className="mt-8 lg:mx-40 mx-5 sm:mx-10 md:mx-20 flex flex-col lg:flex-row md:pb-32 pb-10 gap-10">
        <div className="lg:w-[30%] order-last lg:order-first">
          <div className="lg:sticky top-32">
            <ServiceMenu /> 
          </div>
        </div>
        <div className="lg:w-[70%]">
          <ServiceMain
            title="Автомобильные перевозки"
            titlecard1="Лояльность"
            titlecard2="Надежность"
            paragraph="По определению, автомобильный транспорт — это способ транспортировки грузов с одного пункта в другой по автомагистралям и наземным транспортным средствам безопасным, быстрым, своевременным и дешевым способом. Это часто предпочтительный метод, потому что он дешев в транспортировке на короткие расстояния."
            paragraphcard1="Наша долгосрочная работа продолжается до завершения задачи. Мы устанавливаем прочные и долгосрочные отношения со всеми компаниями, с которыми работаем."
            paragraphcard2="Мы предоставляем точные, надежные и этичные услуги с нашей экспертной командой. Мы применяем самые быстрые и надежные методы для вашего бренда."
            image={Road}
            cardlogo1={medal}
            cardlogo2={badge}
          />
          <div className="mt-10 p-4 bg-white rounded-lg shadow-md">
            <h1 className="md:text-3xl text-xl font-bold text-dark mb-6">Детали</h1>
            <h2 className="md:text-2xl font-bold text-dark mb-4">Автоперевозки</h2>
            <p className="text-gray-500">
              Маршрут:
              <ul className="list-disc ml-5 text-sm md:text-base">
                <li>Восточная часть России: Китайский склад – порт – Уссурийск – Москва (или трансфер в другие города)</li>
                <li>Западная часть России: Китайский склад – порт – Казахстан – Москва (или транзит в другие города)</li>
                <li>Железнодорожный транспорт: Китайский склад – железнодорожная станция – московская станция – московский склад (или транзит в другие города)</li>
              </ul>
            </p>
            <p className="text-gray-500 mt-2 text-sm md:text-base ">
              Сроки: 18-25 дней (со следующего дня после даты получения)
            </p>
            <h3 className="md:text-xl font-semibold text-dark mt-4">
              Города в России, доступные для доставки:
            </h3>
            <p className="text-gray-500 text-sm md:text-base mt-2">
              Москва, Санкт-Петербург, Екатеринбург, Новосибирск, Сочи, Тюмень, Уфа, Казань, Чита, Самара, Иркутск, Пермь, Владивосток, Улан-Удэ, Омск, Якутск, Воронеж, Ростов, Уссурийск, Хабаровск, Краснодар, Красноярск и др.
            </p>
            <h3 className="md:text-xl font-semibold text-dark mt-4">
              Транспортная упаковка:
            </h3>
            <p className="text-gray-500 text-sm md:text-base mt-2">
              В связи с длительными сроками международных перевозок, для предотвращения повреждения товара в дороге (повреждения из-за сдавливания и столкновения деревянных ящиков), а также для предотвращения намокания товара, необходимо делать водонепроницаемую упаковку и упаковку товара в деревянные ящики. Упаковка: деревянные ящики (80 USD за кубический метр), деревянные рамы (50 USD за кубический метр), с дополнительной оплатой за вес. Водонепроницаемая упаковка (скотч и мешки (5 USD за мешок)).
            </p>
            <h3 className="md:text-xl font-semibold text-dark mt-4">
              Товары, имеющие преференцию при таможенном оформлении:
            </h3>
            <p className="text-gray-500 text-sm md:text-base mt-2">
              Компьютер, карманный компьютер MID, планшетный компьютер, мобильный телефон, детали мобильного телефона, лампы и фонари, автозапчасти, строительные материалы, детали оборудования, одежда, обувь и головные уборы, мебель, сумки, кожа, постельные принадлежности, игрушки, изделия народных промыслов, сантехника, продукты питания, медицина, техника и т.д.
            </p>
            <h3 className="md:text-xl font-semibold text-dark mt-4">Страхование:</h3>
            <p className="text-gray-500 text-sm md:text-base mt-2">
              Страховая сумма: 2% от стоимости товара. Если товар будет потерян, мы компенсируем вам полную стоимость товара.
            </p>
            <h3 className="md:text-xl font-semibold text-dark mt-4">
              Компенсация за позднее прибытие:
            </h3>
            <p className="text-gray-500 text-sm md:text-base mt-2">
              Авиа: 0,1$/кг/день, если груз не прибывает в Москву на 13 день после отправки. Автомобильным транспортом: 5$/куб.м/день, если груз не прибывает в Москву на 25 день после отправки.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default RoadHaulage;
