import React, { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, useLocation, Navigate } from 'react-router-dom';
import RootLayout from './components/RootLayout';
import Home from './pages/Home';
import AboutUs from './pages/AboutUs';
import MoneyTransfer from './pages/MoneyTransfer';
import CustomsClearance from './pages/CustomsClearance';
import AirTransport from './pages/AirTransport';
import MaritimeTransport from './pages/MaritimeTransport';
import RailwayTransport from './pages/RailwayTransport';
import Contact from './pages/Contact';
import Services from './pages/Services';
import Calc from './pages/Calc';
import RoadHaulage from './pages/RoadHaulage';
import Manufacturer from './pages/Manufacturer';

const MainComponent = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const page = searchParams.get('page');

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname, location.search]);

  switch (page) {
    case 'about-us':
      return <AboutUs />;
    case 'money-transfer':
      return <MoneyTransfer />;
    case 'customs-clearance':
      return <CustomsClearance />;
    case 'air-transport':
      return <AirTransport />;
    case 'maritime-transport':
      return <MaritimeTransport />;
    case 'railway-transport':
      return <RailwayTransport />;
    case 'contact':
      return <Contact />;
    case 'services':
      return <Services />;
    case 'calculate':
      return <Calc />;
    case 'road-transport':
      return <RoadHaulage />;
    case 'manufacturer-verification':
      return <Manufacturer />;
    default:
      return <Home />;
  }
};

const App = () => {
  return (
    <Router>
      <RootLayout>
        <Routes>
          <Route path="/" element={<MainComponent />} />
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      </RootLayout>
    </Router>
  );
};

export default App;
