import React from 'react';
import { Helmet } from 'react-helmet';
import BgHero from '../components/BgHero';

const Calc = () => {
  const metadata = {
    title: 'Рассчитать - Заполните Форму для Расчета | К002 карго',
    description: 'Заполните форму, и мы свяжемся с вами незамедлительно.',
    keywords: 'Рассчитать, Calculate, 计算, Form, Форма, 表格, Estimation, Расчет, 估算',
    author: 'Транспортная Компания',
    language: 'ru',
    openGraph: {
      title: 'Рассчитать - Заполните Форму для Расчета | К002 карго',
      description: 'Заполните форму, и мы свяжемся с вами незамедлительно.',
      image: 'https://www.k-002.ru/static/media/Logo.33e265985101896cf6b1cb98d2399010.svg', 
      url: 'https://K-002.ru/calculate',
      type: 'website',
    },
  };

  return (
    <div>
      <Helmet>
        <title>{metadata.title}</title>
        <meta name="description" content={metadata.description} />
        <meta name="keywords" content={metadata.keywords} />
        <meta name="author" content={metadata.author} />
        <meta name="language" content={metadata.language} />
        <meta name="robots" content="index, follow" />
        <link rel="canonical" href={metadata.openGraph.url} />
        <meta property="og:title" content={metadata.openGraph.title} />
        <meta property="og:description" content={metadata.openGraph.description} />
        <meta property="og:image" content={metadata.openGraph.image} />
        <meta property="og:url" content={metadata.openGraph.url} />
        <meta property="og:type" content={metadata.openGraph.type} />
      </Helmet>
      <BgHero headline="Рассчитать" />
      <div className="flex items-center justify-center mx-2 md:mx-40">
        <div className="bg-white mt-10 mb-10 lg:w-[60%] w-full p-4 border-2 border-dark rounded-lg shadow-md">
          <div className="mb-4 text-center">
            <p className="font-bold text-dark">Заполните форму, и мы свяжемся с вами незамедлительно.</p>
          </div>
          <iframe
            title="Estimation Form"
            src="https://forms.yandex.ru/cloud/66a4fa7202848f29816e7368/?iframe=1"
            name="ya-form-66a4fa7202848f29816e7368"
            width="100%"
            height="800"
          ></iframe>
        </div>
      </div>
    </div>
  );
}

export default Calc;
