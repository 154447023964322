import { FaPlane, FaShip, FaTrain, FaTruck, FaSearchDollar, FaExchangeAlt, FaCheckCircle } from "react-icons/fa";
import Card from "./Card";
import { useIntersectionObserver } from './useIntersectionObserver';

const OurServices = () => {
  const [setRef1, isVisible1] = useIntersectionObserver({ threshold: 0.3 });
  const [setRef2, isVisible2] = useIntersectionObserver({ threshold: 0.3 });
  const [setRef3, isVisible3] = useIntersectionObserver({ threshold: 0.3 });
  const [setRef4, isVisible4] = useIntersectionObserver({ threshold: 0.3 });
  const [setRef5, isVisible5] = useIntersectionObserver({ threshold: 0.3 });
  const [setRef6, isVisible6] = useIntersectionObserver({ threshold: 0.3 });
  const [setRef7, isVisible7] = useIntersectionObserver({ threshold: 0.3 });

  return (
    <div className="bg-neutral-50">
      <div className="flex items-center flex-col">
        <h1 className="text-dark text-2xl md:text-3xl lg:text-4xl mx-3 text-center md:mt-20 mt-10 font-bold mb-5">Наши Логистические Услуги</h1>
        <p className="text-gray-500 text-center text-sm md:text-base mx-4">Мы здесь для быстрой и надежной доставки. Мы доставляем их грузы на реактивной скорости.</p>
      </div>
      <div className="p-4 md:pt-12 pt-8 md:pb-20 pb-12">
        <div className="grid gap-4 md:gap-8 mx-4 md:mx-10 lg:mx-40 grid-cols-1 md:grid-cols-2 lg:grid-cols-3">
          <div ref={setRef1} className={`element-invisible-our-services ${isVisible1 ? 'slide-in-left-our-services element-visible-our-services' : ''}`}>
            <Card 
              icon={<FaSearchDollar className="h-10 w-10" color="white" />}
              title="Поиск и проверка производителей"
              paragraph="Мы предоставляем услуги поиска и проверки производителей, обеспечивая высокое качество и надежность продукции."
              link="/?page=manufacturer-verification"
            />
          </div>
          <div ref={setRef2} className={`element-invisible-our-services ${isVisible2 ? 'slide-in-right-our-services element-visible-our-services' : ''}`}>
            <Card 
              icon={<FaExchangeAlt size={48} color="white" />}
              title="Перевод и обмен денежных средств"
              paragraph="Мы обеспечиваем безопасный и эффективный перевод и обмен денежных средств с минимальными комиссиями."
              link="/?page=money-transfer"
            />
          </div>
          <div ref={setRef3} className={`element-invisible-our-services ${isVisible3 ? 'slide-in-bottom-our-services element-visible-our-services' : ''}`}>
            <Card 
              icon={<FaCheckCircle size={48} color="white" />}
              title="Таможенное оформление"
              paragraph="Наши специалисты помогут вам пройти все этапы таможенного оформления быстро и без проблем."
              link="/?page=customs-clearance"
            />
          </div>
          <div ref={setRef4} className={`element-invisible-our-services ${isVisible4 ? 'slide-in-left-our-services element-visible-our-services' : ''}`}>
            <Card 
              icon={<FaTruck size={48} color="white" />}
              title="Автоперевозки"
              paragraph="Автомобильный транспорт — это безопасный, быстрый и экономичный способ доставки грузов по дорогам."
              link="/?page=road-transport"
            />
          </div>
          <div ref={setRef5} className={`element-invisible-our-services ${isVisible5 ? 'slide-in-right-our-services element-visible-our-services' : ''}`}>
            <Card 
              icon={<FaPlane size={48} color="white" />}
              title="Авиаперевозки"
              paragraph="Авиаперевозки — это быстрый, хотя и более дорогой, способ транспортировки грузов."
              link="/?page=air-transport"
            />
          </div>
          <div ref={setRef6} className={`element-invisible-our-services ${isVisible6 ? 'slide-in-bottom-our-services element-visible-our-services' : ''}`}>
            <Card 
              icon={<FaShip size={48} color="white" />}
              title="Морские перевозки"
              paragraph="Морские перевозки — это экономичный способ транспортировки товаров на большие расстояния."
              link="/?page=maritime-transport"
            />
          </div>
          <div ref={setRef7} className={`element-invisible-our-services ${isVisible7 ? 'slide-in-left-our-services element-visible-our-services' : ''}`}>
            <Card 
              icon={<FaTrain size={48} color="white" />}
              title="Железнодорожные перевозки из Китая"
              paragraph="Мы предоставляем надежные услуги железнодорожного транспорта, обеспечивая доставку из Китая с минимальными сроками."
              link="/?page=railway-transport"
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default OurServices;
