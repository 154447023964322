import { useState, useEffect } from "react";
import { ChevronLeft, ChevronRight } from "lucide-react";
import { Link } from "react-router-dom";
import slider1 from "../assests/slider1.png";
import slider2 from "../assests/slider3.jpg";

const images = [slider1, slider2];
const texts = [
  {
    title: "Быстрый, Эффективный и Надежный Транспорт",
    subtitle: "С нашим опытом мы доставляем которые другие не могут вовремя.",
    buttonText: "Начать Сейчас",
  },
  {
    title: "Мы Делаем Транспорт Безопасным и Быстрым",
    subtitle: "Мы на пути к осуществлению ваших мечтаний.",
    buttonText: "Начать Сейчас",
  },
];

const HeroQestion = () => {
  const [currentImage, setCurrentImage] = useState(0);
  const [fade, setFade] = useState(false);

  const goToPreviousImage = () => {
    setFade(true);
    setTimeout(() => {
      setCurrentImage((prev) => (prev - 1 + images.length) % images.length);
      setFade(false);
    }, 500); 
  };

  const goToNextImage = () => {
    setFade(true);
    setTimeout(() => {
      setCurrentImage((prev) => (prev + 1) % images.length);
      setFade(false);
    }, 500);
  };

  useEffect(() => {
    const interval = setInterval(goToNextImage, 10000); 
    return () => clearInterval(interval); 
  }, []);

  return (
    <div>
      <section className="relative w-full h-[92vh] sm:h-[93vh] lg:h-[90vh] overflow-hidden">
        {images.map((image, index) => (
          <div
            key={index}
            className={`absolute inset-0 bg-cover  bg-center transition-opacity duration-1000 ease-in-out ${index === currentImage ? 'opacity-100' : 'opacity-0'}`}
            style={{
              backgroundImage: `url(${image})`,
              height: '100vh',
              width: '100vw',
              backgroundSize: 'cover',
              backgroundPosition: 'center',
              
            }}
          />
        ))}
        <div className="absolute inset-0 bg-black/30 flex lg:justify-start lg:items-center lg:pl-20">
          <div className="relative max-w-screen-xl mx-6 sm:px-6 lg:flex lg:h-screen lg:items-center">
            <div className="max-w-xl text-center flex flex-col justify-center items-center h-full lg:text-left lg:max-w-none lg:w-1/2 lg:items-start lg:pl-20">
              <h1 className="text-4xl sm:text-5xl md:text-6xl font-extrabold text-white">
                {texts[currentImage].title}
              </h1>
              <p className="mt-4 max-w-lg text-white lg:text-left font-bold text-base sm:text-lg">
                {texts[currentImage].subtitle}
              </p>
              <div className="mt-8 w-3/4 sm:w-2/3 md:w-1/2 lg:w-3/4">
                <Link
                  to="/?page=contact"
                  className="block w-full rounded bg-dark py-4 text-white font-bold text-sm shadow hover:bg-dark/60 focus:outline-none focus:ring active:dark/40 text-center"
                >
                  {texts[currentImage].buttonText}
                </Link>
              </div>
            </div>
          </div>
        </div>
        <button
          onClick={goToPreviousImage}
          className="absolute hidden lg:block left-4 top-1/2 transform -translate-y-1/2 p-2 border-2 border-white bg-opacity-50 hover:bg-opacity-75 transition-all duration-300"
        >
          <ChevronLeft className="h-10 w-10 text-white" />
        </button>
        <button
          onClick={goToNextImage}
          className="absolute hidden lg:block right-4 top-1/2 transform -translate-y-1/2 p-2 border-2 border-white bg-opacity-50 hover:bg-opacity-75 transition-all duration-300"
        >
          <ChevronRight className="h-10 w-10 text-white" />
        </button>
      </section>
    </div>
  );
};

export default HeroQestion;
