import { useIntersectionObserver } from "./useIntersectionObserver";
import { Award, Monitor, ShieldCheck, Users } from "lucide-react";
import Card from "./Card";

const CardsHome = () => {
  const [setRef, isVisible] = useIntersectionObserver({
    threshold: 0.3,
  });

  return (
    <div ref={setRef} className="bg-dark/95 py-16">
      <div className="grid gap-10 mx-10 md:grid-cols-2 lg:grid-cols-4 lg:gap-4">
        <div className={`card-invisible ${isVisible ? 'card-animation-1' : ''}`}>
          <Card
            icon={<ShieldCheck className="w-10 h-10 text-white" />}
            title="Безопасная доставка"
            paragraph="Доверьте перевозку ваших товаров нашему опытному персоналу и передовым технологиям. Мы гарантируем безопасность и надежность на каждом этапе!"
            contact={true}
          />
        </div>
        <div className={`card-invisible ${isVisible ? 'card-animation-2' : ''}`}>
          <Card
            icon={<Users className="w-10 h-10 text-white" />}
            title="Опытная команда"
            paragraph="Мы предоставляем услуги с нашей опытной командой в результате долгих исследований."
            contact={true}
          />
        </div>
        <div className={`card-invisible ${isVisible ? 'card-animation-3' : ''}`}>
          <Card
            icon={<Award className="w-10 h-10 text-white" />}
            title="Качественное обслуживание"
            paragraph="Мы ориентируемся на высокие стандарты, чтобы предоставить вам лучшие услуги из нашего ассортимента."
            contact={true}
          />
        </div>
        <div className={`card-invisible ${isVisible ? 'card-animation-4' : ''}`}>
          <Card
            icon={<Monitor className="w-10 h-10 text-white" />}
            title="Услуги и технологии"
            paragraph="Мы перевозим ваши товары с использованием новейших технологий в соответствии с международными стандартами качества."
            contact={true}
          />
        </div>
      </div>
    </div>
  );
};

export default CardsHome;
