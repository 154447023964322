import React from 'react';
import { Helmet } from 'react-helmet';
import BgHero from '../components/BgHero';
import ServiceMain from '../components/ServiceMain';
import ServiceMenu from '../components/ServiceMenu';
import Sea from '../assests/Sea.jpg';
import medal from '../assests/medal.png';
import badge from '../assests/badge.png';

const MaritimeTransport = () => {
  const metadata = {
    title: 'Морские перевозки - Надежные Услуги |  К002 карго',
    description: 'Мы предоставляем профессиональные услуги по морским перевозкам.',
    keywords: 'Морские перевозки, Maritime Transport, 海运, Transport, Транспорт, 运输, Logistics, Логистика, 物流',
    author: 'Транспортная Компания',
    language: 'ru',
    openGraph: {
      title: 'Морские перевозки - Надежные Услуги |  К002 карго',
      description: 'Мы предоставляем профессиональные услуги по морским перевозкам.',
      image: 'https://www.k-002.ru/static/media/Logo.33e265985101896cf6b1cb98d2399010.svg', 
      url: 'https://K-002.ru/maritime-transport',
      type: 'website',
    },
  };

  return (
    <div className="bg-neutral-50">
      <Helmet>
        <title>{metadata.title}</title>
        <meta name="description" content={metadata.description} />
        <meta name="keywords" content={metadata.keywords} />
        <meta name="author" content={metadata.author} />
        <meta name="language" content={metadata.language} />
        <meta name="robots" content="index, follow" />
        <link rel="canonical" href={metadata.openGraph.url} />
        <meta property="og:title" content={metadata.openGraph.title} />
        <meta property="og:description" content={metadata.openGraph.description} />
        <meta property="og:image" content={metadata.openGraph.image} />
        <meta property="og:url" content={metadata.openGraph.url} />
        <meta property="og:type" content={metadata.openGraph.type} />
      </Helmet>
      <BgHero headline="Морские перевозки" />
      <div className="mt-8 lg:mx-40 mx-5 sm:mx-10 md:mx-20 flex flex-col lg:flex-row pb-10 md:pb-32 gap-10">
        <div className="lg:w-[30%] order-last lg:order-first">
          <div className="lg:sticky top-32">
            <ServiceMenu />
          </div>
        </div>
        <div className="lg:w-[70%]">
          <ServiceMain
            title="Морские перевозки"
            titlecard1="Лояльность"
            titlecard2="Надежность"
            paragraph="Процесс перевозки товаров по морю и грузовым судам называется морскими перевозками. В логистическом секторе быстрая, качественная транспортировка товаров должна также обеспечивать низкую стоимость перевозки."
            paragraphcard1="Наша долгосрочная работа продолжается до завершения задачи. Мы устанавливаем прочные и долгосрочные отношения со всеми компаниями, с которыми работаем."
            paragraphcard2="Мы предоставляем точные, надежные и этичные услуги с нашей экспертной командой. Мы применяем самые быстрые и надежные методы для вашего бренда."
            image={Sea}  
            cardlogo1={medal} 
            cardlogo2={badge} 
          />
          <div className="mt-10 p-4 bg-white rounded-lg shadow-md">
            <h1 className="md:text-3xl text-xl font-bold text-dark mb-6">Морские перевозки</h1>
            <p className="text-gray-500 text-sm md:text-base">
              Одним из ведущих направлений деятельности нашей компании являются перевозки грузов морским транспортом. Обратившись к нам, вы можете заказать доставку любых грузов. Мы предложим подходящий морской транспорт, разработаем оптимальный маршрут и позаботимся о сокращении ваших расходов. Груз гарантированно прибудет к получателю вовремя. Международная перевозка грузов обеспечивается нами во всех направлениях. Благодаря этому вы можете использовать современный транспорт для перевозок как из стран Европы и Юго-Восточной Азии, так и из Америки в Россию.
            </p>
            <h3 className="md:text-xl font-semibold text-dark mt-4">Преимущества перевозки морским транспортом</h3>
            <p className="text-gray-500 text-sm md:text-base mt-2">
              Перевозка с использованием морских путей отличается массой достоинств. Воспользоваться такой доставкой грузов следует по причине:
            </p>
            <ul className="list-disc ml-5 text-gray-500 text-sm md:text-base mt-2">
              <li>Выгодных расценок. За транспортировку грузов вы не будете переплачивать.</li>
              <li>Универсальности. Морским транспортом можно перевозить все категории грузов (в том числе хрупкие и негабаритные).</li>
              <li>Безопасности. Транспортировка грузов морским транспортом позволяет свести к минимуму риски порчи продукции в пути. Безопасность обеспечивается как непосредственно в ходе перевозки грузов, так и при погрузочных и разгрузочных работах.</li>
            </ul>
            <h3 className="md:text-xl font-semibold text-dark mt-4">Перевозка грузов морскими путями: основные особенности</h3>
            <p className="text-gray-500 text-sm md:text-base mt-2">
              Стоит отметить, что такой способ доставки, как правило, является составляющей частью общего маршрута. Перемещение грузов от поставщика до порта отправки, от порта прибытия до склада осуществляется посредством автомобильного или железнодорожного транспорта.
            </p>
            <p className="text-gray-500 text-sm md:text-base mt-2">
              Организация международных морских перевозок грузов – комплексная услуга, которая включает:
            </p>
            <ul className="list-disc ml-5 text-gray-500 text-sm md:text-base mt-2">
              <li>Разработку оптимального маршрута перевозки морским транспортом в соответствии с типом доставляемых грузов.</li>
              <li>Наземную транспортировку до порта отправления, а в дальнейшем – до склада получателя.</li>
              <li>Размещение и хранение грузов на складской территории.</li>
              <li>Оформление сопутствующей документации для морских отправлений.</li>
              <li>Регулярное отслеживание перемещения грузов на каждом из этапов доставки и предоставление актуальных сведений о его местоположении.</li>
            </ul>
            <p className="text-gray-500 text-sm md:text-base mt-2">
              Мы осуществляем морскую перевозку по всему миру и устанавливаем выгодные тарифы на свои услуги. При этом наши клиенты могут быть полностью уверены в полной сохранности доверенного нам груза и строгом соблюдении сроков доставки.
            </p>
            <h3 className="md:text-xl font-semibold text-dark mt-4">Перевозка: основные и дополнительные услуги</h3>
            <p className="text-gray-500 text-sm md:text-base">
              Нами осуществляются не только грузоперевозки с привлечением морского транспорта. Специалисты предоставляют поддержку и в других направлениях.
            </p>
            <p className="text-gray-500 text-sm md:text-base">
              В перечень услуг в сфере перевозки включены:
            </p>
            <ul className="list-disc ml-5 text-gray-500 text-sm md:text-base mt-2">
              <li>Таможенное оформление грузов.</li>
              <li>Доставка грузов в формате "от двери до двери" с привлечением автомобильного транспорта.</li>
              <li>Разработка индивидуальных морских логистических схем для транспортировки грузов.</li>
              <li>Складской сервис по хранению и маркировке грузов на территории других государств.</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MaritimeTransport;
