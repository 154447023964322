import React from 'react';
import { Helmet } from 'react-helmet';
import BgHero from '../components/BgHero';
import ServiceMain from '../components/ServiceMain';
import ServiceMenu from '../components/ServiceMenu';
import Air from '../assests/customs.jpg';
import medal from '../assests/medal.png';
import badge from '../assests/badge.png';

const CustomsClearance = () => {
  const metadata = {
    title: 'Таможенное оформление - Профессиональные услуги | К002 карго',
    description: 'Мы предоставляем профессиональные услуги по таможенному оформлению.',
    keywords: 'Таможенное оформление, Customs Clearance, 海关清关, Transport, Транспорт, 运输, Logistics, Логистика, 物流',
    author: 'Транспортная Компания',
    language: 'ru',
    openGraph: {
      title: 'Таможенное оформление - Профессиональные услуги | К002 карго',
      description: 'Мы предоставляем профессиональные услуги по таможенному оформлению.',
      image: 'https://www.k-002.ru/static/media/Logo.33e265985101896cf6b1cb98d2399010.svg', 
      url: 'https://K-002.ru/customs-clearance',
      type: 'website',
    },
  };

  return (
    <div className="bg-neutral-50">
      <Helmet>
        <title>{metadata.title}</title>
        <meta name="description" content={metadata.description} />
        <meta name="keywords" content={metadata.keywords} />
        <meta name="author" content={metadata.author} />
        <meta name="language" content={metadata.language} />
        <meta name="robots" content="index, follow" />
        <link rel="canonical" href={metadata.openGraph.url} />
        <meta property="og:title" content={metadata.openGraph.title} />
        <meta property="og:description" content={metadata.openGraph.description} />
        <meta property="og:image" content={metadata.openGraph.image} />
        <meta property="og:url" content={metadata.openGraph.url} />
        <meta property="og:type" content={metadata.openGraph.type} />
      </Helmet>
      <BgHero headline="Таможенное оформление"/>
      <div className="mt-8 lg:mx-40 mx-5 sm:mx-10 md:mx-20 flex flex-col lg:flex-row md:pb-32 pb-10 gap-10">
        <div className="lg:w-[30%] order-last lg:order-first">
          <div className="lg:sticky top-32">
            <ServiceMenu />
          </div>
        </div>
        <div className="lg:w-[70%]">
          <ServiceMain
            title="Таможенное оформление"
            titlecard1="Лояльность"
            titlecard2="Надежность"
            paragraph="Область деятельности: таможня Московского аэропорта, таможня Московского железнодорожного вокзала, Уссурийская таможня, таможня аэропорта Владивосток, таможня порта Владивосток и другие города."
            paragraphcard1="Наша долгосрочная работа продолжается до завершения задачи. Мы устанавливаем прочные и долгосрочные отношения со всеми компаниями, с которыми работаем."
            paragraphcard2="Мы предоставляем точные, надежные и этичные услуги с нашей экспертной командой. Мы применяем самые быстрые и надежные методы для вашего бренда."
            image={Air}
            cardlogo1={medal}
            cardlogo2={badge}
          />
          <div className="mt-10 p-4 bg-white rounded-lg shadow-md">
            <h2 className="md:text-2xl font-bold text-dark mb-4">Процесс таможенного оформления за рубежом</h2>
            <ol className="list-decimal list-inside text-gray-500 mb-4 text-sm md:text-base space-y-2">
              <li>Поручительство: Клиент предоставляет сведения о товаре, месте таможенного оформления и другую информацию, необходимую для оформления.</li>
              <li>Оформление документов: После отгрузки товара, в соответствии с фактическими данными об упаковке и количестве грузовых мест товара, заказчику представляются документы для представления в российскую таможню (коммерческий инвойс, упаковочный лист и т.д.), соответствующих требованиям российской таможенной декларации.</li>
              <li>Процесс сертификации груза: До прибытия груза на место таможенного оформления мы выступаем в качестве агента для завершения подготовки и утверждения российских сертификационных документов коммерческой инспекции, санитарной и карантинной инспекции.</li>
              <li>Предварительное таможенное оформление: За 3 дня до прибытия товара на место таможенного оформления мы подаём необходимые документы и таможенные декларации для оформления в России и осуществляем таможенное оформление товара.</li>
              <li>Уплата таможенных пошлин: Клиент оплачивает соответствующие таможенные пошлины в соответствии с предварительной суммой импортируемого товара, указанной в таможенной декларации.</li>
              <li>Таможенный досмотр: Когда товар прибывает на станцию таможенного оформления, таможня проверяет товар в соответствии с данными декларации.</li>
              <li>Сертификат проверки: Если сведения, указанные в декларации на товары, соответствуют данным таможенной проверки, таможенный инспектор представляет в таможню сертификат о проверке данной партии товаров.</li>
              <li>Таможенный выпуск: После завершения проверки таможня проставляет штамп выпуска на таможенной декларации и регистрирует товар в системе.</li>
              <li>Получение формальностей и сопроводительных документов: После завершения таможенного оформления клиенту будут предоставлены сертификат, свидетельство об освобождении от налогов, копия таможенной декларации и другие соответствующие процессуальные документы.</li>
            </ol>
            <h2 className="md:text-2xl font-bold text-dark mb-4 mt-5">Указания по таможенному оформлению заграницей</h2>
            <ul className="list-disc list-inside text-gray-500 mb-4 text-sm md:text-base space-y-2">
              <li>Подготовить документы, такие как: договор купли-продажи, страховка, коносамент, упаковочный лист, счет-фактура, сертификат происхождения и т.д.</li>
              <li>Страховка в Китае, международное страхование грузов только до порта, не включает риск страхования таможенного оформления, поэтому убедитесь перед отправкой, что страховка распространяется на таможенную очистку.</li>
              <li>Перед отправкой товара на китайской таможне сверьте налог на товары и уточните смогут ли они пройти таможенную очистку.</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CustomsClearance;
