import React from 'react';
import girl1 from '../assests/girl1.jpeg';
import boy1 from '../assests/boy1.jpeg';
import boy2 from '../assests/boy2.jpeg';
import { useIntersectionObserver } from './useIntersectionObserver';

const reviews = [
  {
    name: "Павел Старр",
    rating: 5,
    text: "Отличная работа! Товар пришел в целости и сохранности. Будем обращаться к вам снова.",
    img: boy1,
  },
  {
    name: "Мария Петрова",
    rating: 5,
    text: "Все прошло гладко, но доставка задержалась на пару дней. Тем не менее, довольны услугами.",
    img: girl1,
  },
  {
    name: "Иван Сидоров",
    rating: 5,
    text: "Очень доволен сотрудничеством! Быстрая доставка и отличное обслуживание. Рекомендую всем!",
    img: boy2,
  },
];

const Review = () => {
  const [setRef, isVisible] = useIntersectionObserver({
    threshold: 0.3,
  });

  return (
    <div ref={setRef} className="lg:mt-20 mt-10 lg:mx-40 md:mx-20 mx-10">
      <h2 className="text-2xl lg:text-4xl font-bold text-dark lg:mb-20 mb-10 text-center">
        Ознакомьтесь с проверенными отзывами наших клиентов
      </h2>
      <div className="flex flex-col lg:flex-row gap-0 lg:gap-12">
        {reviews.map((review, index) => (
          <div
            key={index}
            className={`review hover:scale-110 transition-all duration-300 w-full lg:w-1/3 mb-6 md:mb-12 md:p-8 p-4 bg-neutral-100 rounded-xl shadow-lg ${isVisible ? `review-animation-${index + 1}` : 'review-invisible'}`}
          >
            <div className="flex items-center md:mt-4 mt-2 gap-4">
              <img
                className="w-16 h-16 rounded-full"
                src={review.img}
                alt={review.name}
                width={48}
                height={48}
              />
              <div>
                <h4 className="font-semibold">{review.name}</h4>
                <div className="flex justify-center gap-0.5 text-[#2d3d67]">
                  {[...Array(review.rating)].map((_, i) => (
                    <svg
                      key={i}
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-5 w-5"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path
                        d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"
                      />
                    </svg>
                  ))}
                </div>
              </div>
            </div>
            <p className="md:mt-8 mt-4 md:mb-4 mb-2">{review.text}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Review;
