import React from 'react';
import { Helmet } from 'react-helmet';
import BgHero from '../components/BgHero';
import FastLogistics from '../components/FastLogistics';
import Review from '../components/Reviews';
import { FaHandsHelping, FaThumbsUp, FaHistory, FaLifeRing } from 'react-icons/fa';
import { useIntersectionObserver } from '../components/useIntersectionObserver';

const AboutUs = () => {
  const metadata = {
    title: 'О Нас - Ваш Надежный Транспортный Партнер | К002 карго',
    description: 'Мы предлагаем быстрые и надежные транспортные услуги.',
    keywords: 'Транспорт, Logistics, 运输, Fast Logistics, Надежные Транспортные Услуги, Reliable Transportation Services, 快速运输服务',
    author: 'Транспортная Компания',
    language: 'ru',
    openGraph: {
      title: 'О Нас - Ваш Надежный Транспортный Партнер | К002 карго',
      description: 'Мы предлагаем быстрые и надежные транспортные услуги.',
      image: 'https://www.k-002.ru/static/media/Logo.33e265985101896cf6b1cb98d2399010.svg', 
      url: 'https://K-002.ru/about-us',
      type: 'website',
    },
  };

  const cards = [
    {
      icon: <FaThumbsUp size={48} color="white" />,
      title: "Надежность",
      description: "Мы предоставляем точные, надежные и этичные услуги с нашими экспертами. Мы применяем самые быстрые и надежные методы для вашего бренда.",
    },
    {
      icon: <FaHandsHelping size={48} color="white" />,
      title: "Лояльность",
      description: "Наша долгосрочная работа продолжается до тех пор, пока работа не будет завершена. Мы устанавливаем прочные и долгосрочные отношения со всеми компаниями, с которыми работаем.",
    },
    {
      icon: <FaHistory size={48} color="white" />,
      title: "Опыт",
      description: "Благодаря 20-летнему опыту, который мы приобрели за эти годы, специалист предоставляет услуги с новейшими технологическими устройствами.",
    },
  ];

  const [setRef1, isVisible1] = useIntersectionObserver({ threshold: 0.3 });
  const [setRef2, isVisible2] = useIntersectionObserver({ threshold: 0.3 });
  const [setRef3, isVisible3] = useIntersectionObserver({ threshold: 0.3 });
  const [setRef4, isVisible4] = useIntersectionObserver({ threshold: 0.3 });

  return (
    <div>
      <Helmet>
        <title>{metadata.title}</title>
        <meta name="description" content={metadata.description} />
        <meta name="keywords" content={metadata.keywords} />
        <meta name="author" content={metadata.author} />
        <meta name="language" content={metadata.language} />
        <meta name="robots" content="index, follow" />
        <link rel="canonical" href={metadata.openGraph.url} />
        <meta property="og:title" content={metadata.openGraph.title} />
        <meta property="og:description" content={metadata.openGraph.description} />
        <meta property="og:image" content={metadata.openGraph.image} />
        <meta property="og:url" content={metadata.openGraph.url} />
        <meta property="og:type" content={metadata.openGraph.type} />
      </Helmet>
      <BgHero headline="О Нас" />
      <div className="mt-8 mb-20">
        <FastLogistics />
      </div>
      <div className="p-4 text-center bg-white shadow-md text-gray-500">
        {/* Content */}
      </div>
      <div className="p-4 py-10 md:p-10 lg:p-20 bg-dark">
        <div className="lg:mx-40 md:mx-20 flex flex-col md:gap-10 gap-4 items-center justify-center md:grid md:grid-cols-2 lg:flex lg:flex-row">
          <div ref={setRef1} className={`card relative w-full flex flex-col items-center justify-center py-10 px-4 bg-white border border-gray-200 rounded-xl shadow-lg text-center card-invisible ${isVisible1 ? 'card-animation-1' : ''}`}>
            <div className="md:mb-4 mb-3 md:p-7 p-4 rounded-xl bg-dark">
              {cards[0].icon}
            </div>
            <h3 className="md:text-xl font-semibold text-dark">{cards[0].title}</h3>
            <p className="text-sm md:mt-4 mt-2 text-gray-500">{cards[0].description}</p>
          </div>
          <div ref={setRef2} className={`card relative w-full flex flex-col items-center justify-center py-10 px-4 bg-white border border-gray-200 rounded-xl shadow-lg text-center card-invisible ${isVisible2 ? 'card-animation-2' : ''}`}>
            <div className="md:mb-4 mb-3 md:p-7 p-4 rounded-xl bg-dark">
              {cards[1].icon}
            </div>
            <h3 className="md:text-xl font-semibold text-dark">{cards[1].title}</h3>
            <p className="text-sm md:mt-4 mt-2 text-gray-500">{cards[1].description}</p>
          </div>
          <div ref={setRef3} className={`card relative w-full flex flex-col items-center justify-center py-10 px-4 bg-white border border-gray-200 rounded-xl shadow-lg text-center card-invisible ${isVisible3 ? 'card-animation-3' : ''}`}>
            <div className="md:mb-4 mb-3 md:p-7 p-4 rounded-xl bg-dark">
              {cards[2].icon}
            </div>
            <h3 className="md:text-xl font-semibold text-dark">{cards[2].title}</h3>
            <p className="text-sm md:mt-4 mt-2 text-gray-500">{cards[2].description}</p>
          </div>
          <div ref={setRef4} className={`card relative w-full flex flex-col items-center justify-center py-10 px-4 bg-white border border-gray-200 rounded-xl shadow-lg text-center card-invisible ${isVisible4 ? 'card-animation-4' : ''}`}>
            <div className="md:mb-4 mb-3 md:p-7 p-4 rounded-xl bg-dark">
              <FaLifeRing size={48} color="white" />
            </div>
            <h3 className="md:text-xl font-semibold text-dark">Поддержка</h3>
            <p className="text-sm md:mt-4 mt-2 text-gray-500">Мы ускоряем ваши бизнес-процессы, предоставляя профессиональную поддержку. Мы решаем ваши проблемы и предоставляем мгновенные решения.</p>
          </div>
        </div>
      </div>
      <Review />
    </div>
  );
};

export default AboutUs;
