import React from 'react';
import { Helmet } from 'react-helmet';
import BgHero from '../components/BgHero';
import ServiceMain from '../components/ServiceMain';
import ServiceMenu from '../components/ServiceMenu';
import Air from '../assests/money.jpeg';
import medal from '../assests/medal.png';
import badge from '../assests/badge.png';

const MoneyTransfer = () => {
  const metadata = {
    title: 'Перевод и обмен денежных средств - Надежные Услуги |  К002 карго',
    description: 'Мы предоставляем профессиональные услуги по переводу и обмену денежных средств.',
    keywords: 'Перевод и обмен денежных средств, Money Transfer, 汇款与货币兑换, Transfer, Обмен, 兑换, Services, Услуги, 服务',
    author: 'Транспортная Компания',
    language: 'ru',
    openGraph: {
      title: 'Перевод и обмен денежных средств - Надежные Услуги |  К002 карго',
      description: 'Мы предоставляем профессиональные услуги по переводу и обмену денежных средств.',
      image: 'https://www.k-002.ru/static/media/Logo.33e265985101896cf6b1cb98d2399010.svg', 
      url: 'https://K-002.ru/money-transfer',
      type: 'website',
    },
  };

  return (
    <div className="bg-neutral-50">
      <Helmet>
        <title>{metadata.title}</title>
        <meta name="description" content={metadata.description} />
        <meta name="keywords" content={metadata.keywords} />
        <meta name="author" content={metadata.author} />
        <meta name="language" content={metadata.language} />
        <meta name="robots" content="index, follow" />
        <link rel="canonical" href={metadata.openGraph.url} />
        <meta property="og:title" content={metadata.openGraph.title} />
        <meta property="og:description" content={metadata.openGraph.description} />
        <meta property="og:image" content={metadata.openGraph.image} />
        <meta property="og:url" content={metadata.openGraph.url} />
        <meta property="og:type" content={metadata.openGraph.type} />
      </Helmet>
      <BgHero headline="Перевод и обмен денежных средств"/>
      <div className="mt-8 lg:mx-40 mx-5 sm:mx-10 md:mx-20 flex flex-col lg:flex-row pb-10 md:pb-32 gap-10">
        <div className="lg:w-[30%] order-last lg:order-first">
          <div className="lg:sticky top-32">
            <ServiceMenu />
          </div>
        </div>
        <div className="lg:w-[70%]">
          <ServiceMain
            title="Перевод и обмен денежных средств"
            titlecard1="Безопасность"
            titlecard2="Надежность"
            paragraph="Мы представляем нашим клиентам безопасную и надежную систему перевода денежных средств между Россией и Китаем. K002 занимается обменом и переводом валюты по всей России уже 10 лет, что позволяет экономить на комиссиях. Переводы для наших клиентов абсолютно бесплатны!"
            paragraphcard1="Мы принимаем оплату в различных формах и валютах, включая рубли, доллары, юани, евро и другие."
            paragraphcard2="Мы предоставляем точные и надежные услуги с нашей экспертной командой. Мы применяем самые быстрые и безопасные методы для вашего комфорта."
            image={Air}
            cardlogo1={medal}
            cardlogo2={badge}
          />
          <div className="mt-10 p-4 bg-white rounded-lg shadow-md">
            <h2 className="md:text-2xl text-lg font-bold text-dark mb-4">Перевод и обмен денежных средств</h2>
            <p className="text-gray-500 mb-4 text-sm md:text-base">
              Мы представляем нашим клиентам безопасную и надежную систему перевода денежных средств между Россией и Китаем. K002 занимается обменом и переводом валюты по всей России уже 10 лет, что позволяет экономить на комиссиях. Переводы для наших клиентов абсолютно бесплатны!
            </p>
            <h3 className="md:text-xl font-semibold text-dark mt-4">К002 Принимает оплату в различных формах:</h3>
            <div className="text-gray-500 mb-4 text-sm md:text-base">
              <ul>
                <li className="mt-2 text-sm md:text-base">
                  <strong>Формы безналичных расчетов:</strong>
                  <ul className="list-disc list-inside pl-5 text-sm md:text-base">
                    <li>Оплата с банковских карт</li>
                    <li>Электронные деньги и кошельки</li>
                    <li>Оплата с банковского счета</li>
                    <li>Криптовалюта</li>
                  </ul>
                </li>
                <li className="mt-2 ">
                  <strong>Формы наличных расчетов:</strong>
                  <ul className="list-disc list-inside pl-5 text-sm md:text-base">
                    <li>Из рук в руки</li>
                    <li>Через банкомат и терминал</li>
                    <li>Системы денежных переводов</li>
                  </ul>
                </li>
              </ul>
            </div>
            <h3 className="md:text-xl font-semibold text-dark mt-4">Принимаемые валюты:</h3>
            <div className="text-gray-500">
              <ul className="list-disc list-inside text-sm md:text-base">
                <li>Рубль</li>
                <li>Доллар</li>
                <li>Юань</li>
                <li>Евро</li>
                <li>Киргизский сом</li>
                <li>Чеченский нахар</li>
                <li>Сомони</li>
                <li>Узбекский сум</li>
                <li>USDT</li>
                <li>BTC</li>
                <li>ETH</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MoneyTransfer;
