import React from 'react';
import { Helmet } from 'react-helmet';
import BgHero from '../components/BgHero';
import ServiceMain from '../components/ServiceMain';
import ServiceMenu from '../components/ServiceMenu';
import Air from '../assests/verify.jpeg';
import medal from '../assests/medal.png';
import badge from '../assests/badge.png';

const Manufacturer = () => {
  const metadata = {
    title: 'Поиск и проверка производителей - Надежные Услуги | К002 карго',
    description: 'Мы предоставляем профессиональные услуги по поиску и проверке производителей.',
    keywords: 'Производители, Manufacturer Verification, 制造商验证, Verification, Проверка, 验证, Services, Услуги, 服务',
    author: 'Транспортная Компания',
    language: 'ru',
    openGraph: {
      title: 'Поиск и проверка производителей - Надежные Услуги | К002 карго',
      description: 'Мы предоставляем профессиональные услуги по поиску и проверке производителей.',
      image: 'https://www.k-002.ru/static/media/Logo.33e265985101896cf6b1cb98d2399010.svg',
      url: 'https://K-002.ru/manufacturer-verification',
      type: 'website',
    },
  };

  return (
    <div className="bg-neutral-50">
      <Helmet>
        <title>{metadata.title}</title>
        <meta name="description" content={metadata.description} />
        <meta name="keywords" content={metadata.keywords} />
        <meta name="author" content={metadata.author} />
        <meta name="language" content={metadata.language} />
        <meta name="robots" content="index, follow" />
        <link rel="canonical" href={metadata.openGraph.url} />
        <meta property="og:title" content={metadata.openGraph.title} />
        <meta property="og:description" content={metadata.openGraph.description} />
        <meta property="og:image" content={metadata.openGraph.image} />
        <meta property="og:url" content={metadata.openGraph.url} />
        <meta property="og:type" content={metadata.openGraph.type} />
      </Helmet>
      <BgHero headline="Поиск и проверка производителей" />
      <div className="mt-8 lg:mx-40 mx-5 sm:mx-10 md:mx-20 flex flex-col lg:flex-row md:pb-32 pb-10 gap-10">
        <div className="lg:w-[30%] order-last lg:order-first">
          <div className="lg:sticky top-32">
            <ServiceMenu />
          </div>
        </div>
        <div className="lg:w-[70%]">
          <ServiceMain
            title="Поиск и проверка производителей"
            titlecard1="Лояльность"
            titlecard2="Надежность"
            paragraph="Поиск производителей в Китае чем-то может напоминать поиск поставщиков, тем не менее, это совсем не одно и то же, так как присутствует ряд отличительных отличий. Самый главный аспект кроется в том, что производителей существенно меньше в сравнении с перекупщиками и поставщиками."
            paragraphcard1="Наша долгосрочная работа продолжается до завершения задачи. Мы устанавливаем прочные и долгосрочные отношения со всеми компаниями, с которыми работаем."
            paragraphcard2="Мы предоставляем точные, надежные и этичные услуги с нашей экспертной командой. Мы применяем самые быстрые и надежные методы для вашего бренда."
            image={Air}
            cardlogo1={medal}
            cardlogo2={badge}
          />
          <div className="mt-10 p-4 bg-white rounded-lg shadow-md">
            <h2 className="md:text-2xl text-lg font-bold text-dark mb-4">Поиск и проверка производителей</h2>
            <p className="text-gray-500 mb-4 text-sm md:text-base">
              Поиск производителей в Китае чем-то может напоминать поиск поставщиков, тем не менее, это совсем не одно и то же, так как присутствует ряд отличительных отличий. Самый главный аспект кроется в том, что производителей существенно меньше в сравнении с перекупщиками и поставщиками. Судите сами, в роли поставщика может выступить практически любой: и частные предприятия, и совсем небольшие компании, не имеющие ровным счетом никакого отношения к производству. У них выше цены, плюс, возрастает риск обмана.
            </p>
            <p className="text-gray-500 mb-4 text-sm md:text-base">
              Конечно, можно полагаться на удачу и осуществлять поиск производителя товаров самостоятельно, но намного эффективнее – обратиться за помощью в компанию IMPORT EXPRESS. Главная наша функция – это не только поиск, но и совокупный тщательный анализ имеющихся данных в сфере управления закупок, составление новых эффективных схем. По итогам анализа мы сможем выбрать приемлемого партнера для работы в Китае с учетом вашей ниши и запросов.
            </p>
            <h3 className="md:text-xl font-semibold text-dark mt-4">Как мы ищем производителей?</h3>
            <p className="text-gray-500 mb-4 text-sm md:text-base mt-2">
              У каждого клиента целый ряд собственных запросов и целей, которые мы должны удовлетворить в полной мере, осуществляя поиск китайских производителей. Здесь очень важно грамотно обозначить свою задачу, чтобы сотрудники IMPORT EXPRESS могли приступить к делу. Сотрудничество с нами происходит следующим образом:
            </p>
            <ul className="list-disc list-inside text-gray-500 mb-4 text-sm md:text-base">
              <li>Вы отправляете нам запрос, в котором формулируете свои интересы.</li>
              <li>Если у нас остаются какие-либо вопросы, мы их обязательно задаем. Максимально детализированные запросы всегда оптимизируют процесс, так как наличие фотографий, ссылок, технических характеристик увеличивает шансы на нахождение самого лучшего производителя.</li>
            </ul>
            <p className="text-gray-500 mb-4 text-sm md:text-base">
              Как только нами собрана вся база данных от клиента, мы приступаем к отбору производителей в КНР по различным критериям:
            </p>
            <ul className="list-disc list-inside text-sm md:text-base text-gray-500 mb-4">
              <li>Ценовые параметры</li>
              <li>Условия сотрудничества</li>
              <li>Производственные аспекты</li>
              <li>Оперативность</li>
              <li>Качественные характеристики</li>
              <li>Обширность ассортимента</li>
              <li>Условия, на которых осуществляются оптовые поставки</li>
              <li>Возможности получения товарных кредитов и многое другое</li>
            </ul>
            <p className="text-gray-500 mb-4 text-sm md:text-base">
              Естественно, в первую очередь мы ориентируемся на соблюдение интересов заказчика, так как поиск производителей мебели будет отличаться по своим нюансам от поиска производителей, например, обуви.
            </p>
            <h3 className="md:text-xl font-semibold text-dark mt-4">Работаем в интересах клиента</h3>
            <p className="text-gray-500 mb-4 text-sm md:text-base">
              Клиент получает на руки:
            </p>
            <ul className="list-disc list-inside text-gray-500 mb-4 text-sm md:text-base">
              <li>Сведения относительно оговоренного количества предприятий, работающих в интересующей отрасли.</li>
              <li>Поиск компаний производителей предполагает выделение нескольких, отталкиваясь от лучшего соотношения цены и качества, а также иных заданных изначально критериев.</li>
              <li>Полный пакет сведений о контактных данных.</li>
              <li>Данные об организационно-правовой форме, уставном капитале и его сумме, количестве трудящихся.</li>
              <li>Весь ассортимент товаров с мониторингом текущих цен.</li>
              <li>Нахождение оптимального маршрута вкупе с определением стоимости перевозок и таможенного оформления.</li>
            </ul>
            <p className="text-gray-500 mb-4 text-sm md:text-base">
              Это позволяет максимально эффективно использовать полученную информацию для развития вашего бизнеса. Мы делаем ставку в ходе поиска на проверку благонадежности со всех сторон, так как клиент в итоге должен быть уверен в достоверности предлагаемой ему информации не только о самом производителе, но и о выпускаемой им продукции.
            </p>
            <p className="text-gray-500 mb-4 text-sm md:text-base">
              Более того, мы уже не первый год успешно работаем в сфере консалтинга, поэтому за все эти годы сформировали собственную базу данных о компаниях КНР, которые производят достойную по качеству продукцию, поэтому сможем дать ряд полезных рекомендаций и своим клиентам относительно производителей из «белых списков»! Оставьте запрос на поиск производителей в КНР прямо сейчас, и мы приступим к решению поставленной задачи максимально быстро, что сэкономит и ваше время, и деньги, и нервы.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Manufacturer;
