import React from 'react';
import { Helmet } from 'react-helmet';
import BgHero from '../components/BgHero';
import ServiceMain from '../components/ServiceMain';
import ServiceMenu from '../components/ServiceMenu';
import DetailsSection from '../components/Details';
import Air from '../assests/Air.jpg';
import medal from '../assests/medal.png';
import badge from '../assests/badge.png';

const AirTransport = () => {
  const metadata = {
    title: 'Воздушные перевозки - Профессиональные услуги | К002 карго',
    description: 'Мы предоставляем профессиональные услуги по воздушным перевозкам.',
    keywords: 'Воздушные перевозки, Air Transport, 空运, Transport, Транспорт, 运输, Logistics, Логистика, 物流',
    author: 'Транспортная Компания',
    language: 'ru',
    openGraph: {
      title: 'Воздушные перевозки - Профессиональные услуги | К002 карго',
      description: 'Мы предоставляем профессиональные услуги по воздушным перевозкам.',
      image: 'https://www.k-002.ru/static/media/Logo.33e265985101896cf6b1cb98d2399010.svg', 
      url: 'https://K-002.ru/air-transport',
      type: 'website',
    },
  };

  return (
    <div className="bg-neutral-50">
      <Helmet>
        <title>{metadata.title}</title>
        <meta name="description" content={metadata.description} />
        <meta name="keywords" content={metadata.keywords} />
        <meta name="author" content={metadata.author} />
        <meta name="language" content={metadata.language} />
        <meta name="robots" content="index, follow" />
        <link rel="canonical" href={metadata.openGraph.url} />
        <meta property="og:title" content={metadata.openGraph.title} />
        <meta property="og:description" content={metadata.openGraph.description} />
        <meta property="og:image" content={metadata.openGraph.image} />
        <meta property="og:url" content={metadata.openGraph.url} />
        <meta property="og:type" content={metadata.openGraph.type} />
      </Helmet>
      <BgHero headline="Воздушные перевозки" />
      <div className="mt-8 lg:mx-40 mx-5 sm:mx-10 md:mx-20 flex flex-col lg:flex-row md:pb-32 pb-10 gap-10">
        <div className="lg:w-[30%] order-last lg:order-first">
          <div className="lg:sticky top-32">
            <ServiceMenu />
          </div>
        </div>
        <div className="lg:w-[70%]">
          <ServiceMain
            title="Воздушные перевозки"
            titlecard1="Лояльность"
            titlecard2="Надежность"
            paragraph="Авиаперевозки является одним из видов транспорта в мире, который осуществляется самолетами.  Авиаперевозки, который дороже, чем другие методы логистики в мире, в последнее время часто предпочитается."
            paragraphcard1="Наша долгосрочная работа продолжается до завершения задачи. Мы устанавливаем прочные и долгосрочные отношения со всеми компаниями, с которыми работаем."
            paragraphcard2="Мы предоставляем точные, надежные и этичные услуги с нашей экспертной командой. Мы применяем самые быстрые и надежные методы для вашего бренда."
            image={Air}
            cardlogo1={medal}
            cardlogo2={badge}
          />
          <DetailsSection />
        </div>
      </div>
    </div>
  );
};

export default AirTransport;
