import { Link } from 'react-router-dom';
import bg from "../assests/header-2.png"; 

const BgHero = ({headline}) => {
    return (
        <div>
            <div className="relative w-full h-[578px] md:h-[90vh] lg:h-[476px]">
                <img
                    src={bg}
                    alt="background"
                    className="absolute inset-0 object-cover z-0 w-full h-full"
                />
                <div className="relative flex flex-col justify-center items-center h-full z-10 text-center text-white">
                    <h1 className="lg:text-5xl text-3xl font-bold mt-32">{headline}</h1>
                    <p className="mt-4 text-lg">
                        <Link to="/" className="hover:underline mx-2">
                         Главная
                        </Link>
                        | {headline}
                    </p>
                </div>
            </div>
        </div>
    );
}

export default BgHero;
