import { useIntersectionObserver } from "./useIntersectionObserver";
import { Award, Users, Handshake } from "lucide-react";
import bg from "../assests/header-3.png";

const Branches = () => {
  const [setRef, isVisible] = useIntersectionObserver({
    threshold: 0.3,
  });

  return (
    <div className="branches-container">
      <div ref={setRef} className="flex flex-col lg:flex-row bg-dark md:mt-20 mt-10 lg:bg-white">
        <div className="w-full lg:w-[50%] flex flex-col items-center text-white lg:text-dark justify-center p-7 md:my-5">
          <h1 className={`mb-2 md:mb-4 text-xl font-bold text-center ${isVisible ? 'visible slide-in-left' : 'invisible'}`}>
            Мы доставим это в будущее безопасно
          </h1>
          <h1 className={`text-4xl md:text-5xl font-extrabold text-center ${isVisible ? 'visible slide-in-right' : 'invisible'}`}>
            Мы делаем невозможное возможным
          </h1>
          <p className={`text-center mt-4 md:mt-10 font-light ${isVisible ? 'visible fade-in' : 'invisible'}`}>
            Благодаря нашему опыту, мы преодолеваем границы в наших логистических услугах и безопасно осуществляем поставки. Мы движемся к нашим целям, приняв быстрые и безопасные перевозки в качестве принципа.
          </p>
          <div className="md:mt-10 mt-6 flex justify-around w-full lg:text-dark text-white max-w-[400px]">
            <div className={`flex flex-col gap-4 items-center ${isVisible ? 'visible fade-in' : 'invisible'} delay-100`}>
              <Award className="h-14 w-14 md:h-20 md:w-20" />
              <p className="mt-2">Опыт</p>
              <p className="text-2xl font-bold">23</p>
            </div>
            <div className={`flex flex-col gap-4 items-center ${isVisible ? 'visible fade-in' : 'invisible'} delay-200`}>
              <Users className="h-14 w-14 md:h-20 md:w-20" />
              <p className="mt-2">Работников</p>
              <p className="text-2xl font-bold">156</p>
            </div>
            <div className={`flex flex-col gap-4 items-center ${isVisible ? 'visible fade-in' : 'invisible'} delay-300`}>
              <Handshake className="h-14 w-14 md:h-20 md:w-20" />
              <p className="mt-2">Поставщиков</p>
              <p className="text-2xl font-bold">953</p>
            </div>
          </div>
        </div>
        <div className="w-full hidden lg:flex lg:w-[50%]">
          <img src={bg} alt="Branches Background" className="object-cover w-full h-full" />
        </div>
      </div>
    </div>
  );
};

export default Branches;
