import Card from "./Card";
import divider from "../assests/divider.jpg";

const ServiceMain = ({ title, paragraph, paragraphcard1, paragraphcard2, titlecard1, titlecard2, image, cardlogo1, cardlogo2 }) => {
  return (
    <div className="flex flex-col w-full -mb-4">
      <img src={image} alt="service" className="rounded-md" />
      <div className="p-2 md:p-7 flex flex-col gap-5">
        <h1 className="text-dark font-bold text-2xl lg:text-3xl">{title}</h1>
        <p className="text-gray-500">{paragraph}</p>
        <img className="mb-5 w-[120px]" src={divider} alt="divider" />
      </div>
      <div className="flex flex-col md:flex-row md:gap-10 gap-5">
        <Card contact={true} icon={<img src={cardlogo1} alt="loyalty" className="w-10 h-10 text-primary" />} title={titlecard1} paragraph={paragraphcard1} />
        <Card contact={true} icon={<img src={cardlogo2} alt="reliability" className="w-10 h-10 text-primary" />} title={titlecard2} paragraph={paragraphcard2} />
      </div>
    </div>
  );
}

export default ServiceMain;
