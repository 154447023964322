// src/pages/Home.js
import React from 'react';
import { Helmet } from 'react-helmet';
import CardsHome from '../components/CardsHome';
import FastLogistics from '../components/FastLogistics';
import HeroQestion from '../components/HeroPage'; 
import Branches from '../components/Branches';
import OurServices from '../components/Services';
import Reviews from '../components/Reviews';

const Home = () => {
  const metadata = {
    title: 'К002 карго | Доставка товаров из Китая',
    description: 'Мы предоставляем широкий спектр транспортных и логистических услуг.',
    keywords: 'Услуги, Services, 服务, Transport, Транспорт, 运输, Logistics, Логистика, 物流',
    author: 'Транспортная Компания',
    language: 'ru',
    openGraph: {
      title: ' К002 карго | Доставка товаров из Китая',
      description: 'Мы предоставляем широкий спектр транспортных и логистических услуг.',
      image: 'https://www.k-002.ru/static/media/Logo.33e265985101896cf6b1cb98d2399010.svg', 
      url: 'https://K-002.ru',
      type: 'website',
    },
  };

  return (
    <main>
      <Helmet>
        <title>{metadata.title}</title>
        <meta name="description" content={metadata.description} />
        <meta name="keywords" content={metadata.keywords} />
        <meta name="author" content={metadata.author} />
        <meta name="language" content={metadata.language} />
        <meta property="og:title" content={metadata.openGraph.title} />
        <meta property="og:description" content={metadata.openGraph.description} />
        <meta property="og:image" content={metadata.openGraph.image} />
        <meta property="og:url" content={metadata.openGraph.url} />
        <meta property="og:type" content={metadata.openGraph.type} />
      </Helmet>
      <HeroQestion />
      <FastLogistics Homepage={true} />
      <CardsHome />
      <Reviews/>
      <Branches/>
      <OurServices/>
    </main>
  );
}

export default Home;
