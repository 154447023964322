import { ChevronRight } from "lucide-react";
import { Link, useLocation } from "react-router-dom";

const ServiceItem = ({ item, path }) => {
  const location = useLocation();
  const isActive = location.pathname === path;

  return (
    <Link to={path || "#"}>
      <div className={`p-3 bg-dark hover:bg-admin1/20 flex transition-all md:text-xl text-sm duration-300 items-center ${isActive ? 'bg-admin1/30' : ''}`}>
        <ChevronRight className="w-6 h-6 mx-2" />
        <span>{item}</span>
      </div>
    </Link>
  );
};

export default ServiceItem;
