import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { FaVk, FaTelegramPlane, FaWhatsapp } from 'react-icons/fa';
import { SiWechat } from 'react-icons/si';
import { MdEmail } from 'react-icons/md';

const Footer = () => {
  const [showForm, setShowForm] = useState(false);

  const handleButtonClick = () => {
    setShowForm(true);
  };

  return (
    <footer style={{color: 'white' }} className='bg-dark/95'>
      <div className="mx-auto max-w-screen-xl px-4 sm:px-6 lg:px-8 ">
        <div className="lg:grid lg:grid-cols-2">
          <div className="border-b border-gray-200 md:py-8 lg:order-last lg:border-b-0 lg:border-s lg:py-16 lg:ps-16">
            <div className="block lg:hidden">
              <svg className="h-8" viewBox="0 0 118 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              </svg>
            </div>

            <div className="mt-8 space-y-4 lg:mt-0">
              <span className="hidden h-1 w-10 rounded bg-white lg:block"></span>

              <div>
                <h2 className="text-2xl font-medium">Присоединиться к нашей команде</h2>
                <p className="mt-4 max-w-lg text-gray-200">
                  Если вы хотите присоединиться к нам и добиться успеха вместе, просто заполните форму, и мы свяжемся с вами.
                </p>
              </div>

              <div className="rounded-md border border-gray-100 p-2 focus-within:ring sm:flex sm:items-center sm:gap-4" style={{ overflow: 'hidden' }}>
                {!showForm ? (
                  <button
                    onClick={handleButtonClick}
                    className="mt-1 w-full flex items-center justify-center rounded bg-white px-6 py-3 text-sm font-bold uppercase tracking-wide text-dark transition-none hover:bg-white/80 sm:mt-0"
                  >
                    Заполнить форму
                  </button>
                ) : (
                  <iframe
                    src="https://forms.yandex.ru/cloud/66ab386cd04688134d51c9dc/?iframe=1"
                    name="ya-form-66ab386cd04688134d51c9dc"
                    width="100%"
                    height="370px"
                    className="rounded-lg"
                    title="Join our team"
                  ></iframe>
                )}
              </div>
            </div>
          </div>

          <div className="pb-8 lg:pb-16 lg:pe-16">
            <div className="hidden lg:block">
              <svg className="h-8" viewBox="0 0 118 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                {/* SVG content here */}
              </svg>
            </div>

            <div className="mt-8 grid grid-cols-1 gap-8 sm:grid-cols-2">
              <div>
                <p className="font-medium">Услуги</p>
                <ul className="mt-6 space-y-4 text-sm">
                  <li>
                    <Link to="/?page=manufacturer-verification">
                      <span className="text-gray-200 transition hover:opacity-75">Поиск и проверка производителей</span>
                    </Link>
                  </li>
                  <li>
                    <Link to="/?page=money-transfer">
                      <span className="text-gray-200 transition hover:opacity-75">Перевод и обмен денежных средств</span>
                    </Link>
                  </li>
                  <li>
                    <Link to="/?page=customs-clearance">
                      <span className="text-gray-200 transition hover:opacity-75">Таможенное оформление</span>
                    </Link>
                  </li>
                  <li>
                    <Link to="/?page=road-transport">
                      <span className="text-gray-200 transition hover:opacity-75">Автоперевозки</span>
                    </Link>
                  </li>
                  <li>
                    <Link to="/?page=air-transport">
                      <span className="text-gray-200 transition hover:opacity-75">Авиаперевозки</span>
                    </Link>
                  </li>
                  <li>
                    <Link to="/?page=maritime-transport">
                      <span className="text-gray-200 transition hover:opacity-75">Морские перевозки</span>
                    </Link>
                  </li>
                  <li>
                    <Link to="/?page=railway-transport">
                      <span className="text-gray-200 transition hover:opacity-75">Железнодорожные перевозки из Китая</span>
                    </Link>
                  </li>
                </ul>
              </div>

              <div>
                <p className="font-medium">Компания</p>
                <ul className="mt-6 space-y-4 text-sm">
                  <li>
                    <Link to="/?page=about-us">
                      <span className="text-gray-200 transition hover:opacity-75">О нас</span>
                    </Link>
                  </li>
                  <li>
                    <Link to="/?page=contact">
                      <span className="text-gray-200 transition hover:opacity-75">Контакты</span>
                    </Link>
                  </li>
                  <li>
                    <Link to="/?page=services">
                      <span className="text-gray-200 transition hover:opacity-75">Услуги</span>
                    </Link>
                  </li>
                  <li>
                    <Link to="/?page=calculate">
                      <span className="text-gray-200 transition hover:opacity-75">Рассчитать</span>
                    </Link>
                  </li>
                </ul>
                <div className="mt-6 space-y-2 text-sm">
                  <div className="flex gap-2">
                    <a href="mailto:k002kk@bk.ru" target="_blank" rel="noopener noreferrer">
                      <MdEmail className="h-6 w-6 text-gray-300 transition hover:text-white" />
                    </a>
                    <a href="http://wa.me/+79165150002" target="_blank" rel="noopener noreferrer">
                      <FaWhatsapp className="h-6 w-6 text-gray-300 transition hover:text-white" />
                    </a>
                    <a href="https://t.me/k002cargo1" target="_blank" rel="noopener noreferrer">
                      <FaTelegramPlane className="h-6 w-6 text-gray-300 transition hover:text-white" />
                    </a>
                    <a href="https://vk.com/k002k" target="_blank" rel="noopener noreferrer">
                      <FaVk className="h-6 w-6 text-gray-300 transition hover:text-white" />
                    </a>
                    <a href="tel:+8613614637317" target="_blank" rel="noopener noreferrer">
                      <SiWechat className="h-6 w-6 text-gray-300 transition hover:text-white" />
                    </a>
                  </div>
                </div>
              </div>
            </div>

            <div className="mt-8 border-t border-gray-100 pt-8">
              <ul className="flex flex-wrap gap-4 text-xs">
                <li>
                  <Link to="#">
                    <span className="text-gray-300 transition hover:opacity-75">Условия и положения</span>
                  </Link>
                </li>
                <li>
                  <Link to="#">
                    <span className="text-gray-300 transition hover:opacity-75">Политика конфиденциальности</span>
                  </Link>
                </li>
                <li>
                  <Link to="#">
                    <span className="text-gray-300 transition hover:opacity-75">Cookies</span>
                  </Link>
                </li>
              </ul>
              <p className="mt-8 text-xs text-gray-300">&copy; 2024. k002. Все права защищены.</p>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
